import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import Layout from "../components/Layout";
import { Spin } from "antd";
import QueryView from "../page/QueryView";
import PdfView from "../page/pdf";

// 使用 lazy 函数动态加载组件
const Home = lazy(() => import("../page/Home"));
const CreditAnalysis = lazy(() => import("../page/CreditAnalysis"));
const Report = lazy(() => import("../page/Report"));
const Manage = lazy(() => import("../page/Manage"));
const Dashboard = lazy(() => import("../page/Dashboard"));
const IndustrialResearch = lazy(() => import("../page/IndustrialResearch"));
const MarketingActivities = lazy(() => import("../page/MarketingActivities"));
const RatingChanges = lazy(() => import("../page/RatingChanges"));
const UserManage = lazy(() => import("../page/UserManage"));
const SubAccountManage = lazy(() => import("../page/SubAccountManage"));
const PermissionManage = lazy(() => import("../page/PermissionManage"));
const RiskInfo = lazy(() => import("../page/RiskInfo"));
const RiskMonitor=lazy(()=>import("../page/RiskMonitor"))

const LazyLoadWrapper = (Component) => (props) =>
  (
    <Suspense fallback={<Spin size="large" fullscreen />}>
      <Component {...props} />
    </Suspense>
  );

const CreditAnalysisComponent = LazyLoadWrapper(CreditAnalysis);
const HomeComponent = LazyLoadWrapper(Home);
const ReportComponent = LazyLoadWrapper(Report);
const ManageComponent = LazyLoadWrapper(Manage);
const DashboardComponent = LazyLoadWrapper(Dashboard);
const IndustrialResearchComponent = LazyLoadWrapper(IndustrialResearch);
const MarketingActivitiesComponent = LazyLoadWrapper(MarketingActivities);
const RatingChangesComponent = LazyLoadWrapper(RatingChanges);
const UserManageComponent = LazyLoadWrapper(UserManage);
const SubAccountManageComponent = LazyLoadWrapper(SubAccountManage);
const PermissionManageComponent = LazyLoadWrapper(PermissionManage);
const RiskInfoComponent = LazyLoadWrapper(RiskInfo);
const RiskMonitorComponent = LazyLoadWrapper(RiskMonitor);

const routes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/home",
        element: <HomeComponent />,
      },
      {
        path: "/creditAnalysis",
        element: <CreditAnalysisComponent />,
      },
      {
        path: "/queryView",
        element: <QueryView />,
      },
	  {
		  path:'/PdfView',
		  element:<PdfView/>
	  },
      {
        path: "/creditRisks",
        element: <DashboardComponent />,
      },
      {
        path: "/riskWarning",
        element: <ReportComponent />,
      },
      {
        path: "/earlyWarning",
        element: <ManageComponent />,
      },
      {
        path: "/industrialResearch",
        element: <IndustrialResearchComponent />,
      },
      {
        path: "/marketingActivities",
        element: <MarketingActivitiesComponent />,
      },
      {
        path: "/ratingChanges",
        element: <RatingChangesComponent />,
      },
      {
        path: "/userManage",
        element: <UserManageComponent />,
      },
      {
        path: "/SubAccountManage",
        element: <SubAccountManageComponent />,
      },
      {
        path: "/permissionManage",
        element: <PermissionManageComponent />,
      },
      {
        path: "/riskInfo",
        element: <RiskInfoComponent />,
      },
      {
        path: "/riskMonitor",
        element: <RiskMonitorComponent />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/home" />,
  },
];

export default routes;
