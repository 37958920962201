import service from "@/utils/request"

const riskinfo = {
    // 获取行业风险列表
    getIndustryRisk(params) {
      return service.request({ url: "industryRisk/getIndustryRisk", method: "get", params })
    },
    getOpinisRisk(params) {
      return service.request({url:"publicOpinion/getPageByIndustry",method:"get",params})
    },
    getIndustryPolicyist(params) {
      return service.request({url:"industryQuality/getIndustryPolicyList",method:"get",params})
    },
    getIndustryQuality(params) {
      return service.request({url:"industryQuality/getIndustryQuality",method:"get",params})
    },
    getPcaInfo(params) {
      return service.request({url:"company/getIndustryPcaInfo",method:"get",params})
    },
    getPcaPerInfo(params) {
      return service.request({url:"company/getQualityDistribution",method:"get",params})
    }
  };
  
  export default riskinfo;