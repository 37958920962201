let HOST_NAME;

switch (process.env.NODE_ENV) {
  case "development":
    HOST_NAME = "/api";
    break;
  case "production":
    // HOST_NAME = "http://pca-test.api.powerlaunch.cn"; //测试
    // HOST_NAME = "http://pca-preprod.api.powerlaunch.cn"; //预测试
    HOST_NAME = "https://pca.api.powerlaunch.cn"; //正式
    break;
  default:
    HOST_NAME = "";
}

export default {
  HOST_NAME,
  //  /company/getInfo
  HOST_NAME_GET_INFO: "/company/getInfo",
  
  HOST_NAME_COMPANY_LIST: "/company/list",
  HOST_NAME_COMPANY_GET_INDUSTRY_LIST: "/company/getIndustryList",
  HOST_NAME_COMPANY_GET_TYPE_LIST: "/company/getTypeList",
  HOST_NAME_COMPANY_FINANCE: "/companyFinance/companyFinance",
  // Report
  HOST_NAME_COMPANY_GET_REPORT_LIST: "/researchReport/getReportList",

  HOST_NAME_COMPANY_GET_RATE_INFO: "/entityRate/getRateInfo",
  HOST_NAME_COMPANY_GET_ADJUSTINFO: "/entityQuality/adjustInfo",

  HOST_NAME_VIDEO_GET_INDUSTRY_LIST: "/spgVideo/getIndustryList",
  HOST_NAME_VIDEO_GET_VIDEO_INFO: "/spgVideo/getVideoInfo",
  // riskMonitor
  HOST_NAME_GETOPINIONNUMS: "/publicOpinion/getOpinionNums",
  HOST_NAME_GETOPINIONPAGE:"/publicOpinion/getPage",
  HOST_NAME_GETINDUSTRYPCAINFO :"/company/getIndustryPcaInfo",
  HOST_NAME_GETCHNAGEDISTRIBUTION:"/company/getChangeDistribution",
  HOST_NAME_GETCHANGECOMPANYLIST:"/company/getChangeCompanyList",
  HOST_NAME_GETINVOLVECOMPANY:"/publicOpinion/getInvolveCompany",
};
