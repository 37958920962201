import actionTypes from '@/redux/type'

export const initialState = {
  platform_device: 'Desktop', //设备类型
}
export const reducer = () => {
  return [
    // 缓存设备类型
    { type: actionTypes.SET_PLATFORM_DEVICE_TYPE, state: (state, action)=> ({
        ...state, 
        platform_device: action.payload
      }) 
    },
  ]
}
