import React, {useEffect, useState, useRef} from 'react'
import { deepCopy, isArray, isObject } from '@/utils'
import api from '@/api'
import ReactEcharts from 'echarts-for-react'
import { decode } from 'js-base64'
import { Table } from 'antd'
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import downloadImg from '@/assets/images/queryView/downloadf.png'
import jsPDF from "jspdf"
import s from './index.module.scss'
let isSmillScreen = false
const FI_LEFT = [
  {title:'评级基准', variable:'anchor'},
  {title:'业务状况', variable:'businessProfile'},
  {title:'资本与盈利性', variable:'capitalProfitability'},
  {title:'风险状况', variable:'riskProfile'},
  {title:'融资与流动性', variable:'financingLiquidity'},
  {title:'补充调整', variable:'additionalAdjustment'},
  {title:'个体信用质量', variable:'sacp'},
  // {title:'支持方的信用质量', variable:'supportIcrOrSacp'},
  {title:'外部支持重要性', variable:'externalSupportImportance'},
  {title:'外部影响', variable:'finalNotching'},
  {title:'未经调整集团信用质量（如适用）', variable:'unadjustedSacp'},
  {title:'控股公司的特有风险因素（如适用）', variable:'fhcRisk'},
], CORP_LEFT = [
  {title:'业务/经营状况', variable:'brp'},
  {title:'行业名称', variable:'industryName'},
  {title:'行业风险', variable:'industryRisk'},
  {title:'竞争地位', variable:'competitivePosition'},
  {title:'财务风险', variable:'frp'},
  {title:'评级基准', variable:'anchor'},
  {title:'个体信用质量', variable:'sacp'},
  {title:'外部影响', variable:'externalImpact'},
  {title:'主体信用评级结果', variable:'icr'},
]
const OldDetail = (props) => {
	const data = props.data
	const getEchartsRef = useRef(null);
	const basicData = props.data || null
	const [columns, setColumns] = useState([])
	const [dataSource, setDataSource] = useState({})
	const [tableData, setTableData] = useState([])
	const [chartsOptions, setChartsOptions] = useState({});
	const [chooseZb,setChooseZb] = useState(false)
	const [analyticalTextData, setAnalyticalTextData] = useState([])
	const [currentFlowName,setCurrentFlowName] = useState('个体信用质量')
	const [ impact, setImpact ] = useState([])
	const [isFIType, setIsFIType] = useState(false);
	const [chartKey, setChartKey] = useState(true);
	const [currentAllData, setCurrentAllData] = useState({});
	const [ listData, setListData ] = useState([])
	const [currentRardaData, setCurrentRardaData] = useState([]);
	const [ echartsData, setEchartsData ] = useState([])
	
	const [ imageUrl, setImageUrl ] = useState('')
	const getEcharts = async (copyData) => {
		let result = await api.getQualityDistributionFI({sector:copyData?.sector})
		if(result?.code == 200){
			setEchartsData(result.data)
			// setTimeout(()=>{
			// 	handleEchartsToImage()
			// },1000)
		}
	}
	const yewuzhuangkuang = () => {
	  let options = {
	    title: {
	      text: '业务状况',
	      left: "center",
	      top: 0
	    },
	    tooltip: {},
	    legend: {
	      show: true
	    },
	    grid: {
	      left: "50px", right: "50px",bottom:'30px'
	    },
	    xAxis: {
	      data: ["+2", "+1", "0", "-1", "-2", "-3"],
	    },
	    yAxis: {
	      show:false,
	    },
	    series: [
	      {
	        type: "bar",
	        data: filterData(data),
	      },
	    ],
	  }
	  return options;
	}
	const getChartOptionsDetail = (type = 1,title) => {
		let cData =  []
		cData = props.data?.doc.fiCharts.bankCharts.bankChartsData['chartData_'+type]
		let xAxis = []
		let yAxis = []
		let configY = []
		let colors = []
		cData.data.map(item=>{
			xAxis.push(item[0])
			if(type > 6){
				configY.push(item[1].split('|'))
				colors.push(item[2].split('|'))
			}else{
				configY.push(item[3].split('|'))
				colors.push(item[4].split('|'))
			}
			
		})
		let maxLength = 0
		configY.map(item=>{
			if(item.length >= maxLength) maxLength = item.length
		})
		for(let i=0;i<maxLength;i++){
			let seriesData = configY.reduce((pre, el, index) => {pre.push(el[i]);return pre;}, [])
			let color =  colors.reduce((pre, el, index) => {pre.push(el[i]);return pre;}, [])
			yAxis.push(
				{
					type: 'bar',
					stack: 'Ad',
					data:seriesData,
					itemStyle: {
					color: function (params) {
						// 根据params的
						//返回对应的颜色
						return color[params.dataIndex]
					  }
					}
				}
			)
		}
		let options = {
			title:  [
			  {
				text: title,
				left: "center",
				top:0,
			  },
			  {
				left: "left",
				// 标题
				subtext: '银行数量',
				extVerticalAlign: 'middle',
				orientation: 'vertical',
				// 副标题样式
				subtextStyle: {
				  textVerticalAlign: 'middle',
				  orientation: 'vertical',
				},
			  },
			  {
				left: "bottom",
				// 标题
				subtext: '银行数量1',
				extVerticalAlign: 'middle',
				orientation: 'vertical',
				// 副标题样式
				subtextStyle: {
				  textVerticalAlign: 'middle',
				  orientation: 'vertical',
				},
			  },
			],
		  tooltip: {
			trigger: 'axis',
			axisPointer: {
			  type: 'shadow'
			}
		  },
		  grid: {
			left: '3%',
			right: '3%',
			bottom: '3%',
			top:'20%',
			containLabel: true
		  },
		  xAxis: [
			{
			  type: 'category',
			  data: xAxis
			}
		  ],
		  yAxis: [
			{
			  type: 'value',
			  axisLine: {
				show: true,
			  },
			}
		  ],
		  series: yAxis
		};
		return options
	}
	const getChartOptions = (type) => {
		let cData =  []
		cData = type === 2?echartsData?.qualityDistributionFIVOList || []:echartsData?.sacpDistributionFIVOList|| []
		let x = {}
		let xAxis = []
		let yAxis = []
		cData.map(item=>{
			if(!x.hasOwnProperty(item.industries)) x[item.industries] = []
			if(xAxis.indexOf(item.quality)<0) xAxis.push(item.quality)
			x[item.industries].push(item)
		})
		
		for(let item in x) {
			let seriesData = x[item].reduce((pre, el, index) => {pre.push(el.qualityNum);return pre;}, [])
			yAxis.push(
			{
				name:item,
				type: 'bar',
				stack: 'Ad',
				data:seriesData
			})
		}
	    let options = {
			title: {
			  text: '600家主要金融机构潜在个体信用质量测评结果分布示意',
			  left: "center",
			  top: 0
			},
		  tooltip: {
			trigger: 'axis',
			axisPointer: {
			  type: 'shadow'
			}
		  },
		  legend: {
			orient:'vertical',
			right:'10px',
			top:'35%'
		  },
		  grid: {
			left: '3%',
			right: '20%',
			bottom: '3%',
			top:'20%',
			containLabel: true
		  },
		  xAxis: [
			{
			  type: 'category',
			  data: xAxis
			}
		  ],
		  yAxis: [
			{
			  type: 'value'
			}
		  ],
		  series: yAxis
		};
	  return options;
	}
	const filterData = (data) => {
	  return echartsData.map((item, index) => {
	    return {
	      key: index,
	      value: item.qualityNum,
	      itemStyle: {
	        color: getColor(index),
	      }
	    }
	  });
	}
	const getColor = (number = 0) => {
	  let num = number + 1;
	  switch (true) {
	    case num >= 1 && num <= 5:
	      return '#0a6b5b';
	    case num >= 6 && num <= 9:
	      return '#06927c';
	    case num >= 7 && num <= 9:
	      return '#08c3a5';
	    case num >= 10 && num <= 12:
	      return '#08c3a5';
	    case num >= 13 && num <= 15:
	      return '#ffac17';
	    case num >= 16 && num <= 18:
	      return '#d6002a';
	    default:
	      return '#c40067'
	  }
	}
	const handleEchartsToImage = () => {
	    if (getEchartsRef.current) {
	      // 获取 Echarts 实例
	      const echartsInstance = getEchartsRef.current.getEchartsInstance();
	      // 使用 getDataURL 方法生成图片的 base64 URL
	      const imageUrl = echartsInstance.getDataURL({
	        type: 'png',
	        pixelRatio: 2,
	        backgroundColor: '#fff',
	      });
		  setImageUrl(imageUrl)
	    }
	};
	const replaceNewlinesWithTwo = (text) => {
		let configContent = text.replace(/^[\r\n]+/, '').replace(/\r/g, '\n')
		return configContent.replace(/\n+/g, '\n\n')
	}
	useEffect(() => {
		const copyData = deepCopy(props.data)
		if(copyData){
			setIsFIType(props.data?.sector == "金融机构");
			detailSomeData(props)
			getEcharts(copyData)
			let config = getCompanyInfoData('left',copyData?.sector=='金融机构')
			setListData(config)
			const configImpact = []
			for(let item in copyData?.doc.adjustmentItem){
				configImpact.push({name:item,val:copyData?.doc.adjustmentItem[item]})
			}
			setImpact(configImpact)
			const analyticalText = copyData?.doc.analyticalText
			let arr = []
			if (analyticalText && analyticalText.length > 0) {
			  analyticalText.map(item => {
			    if (item.contentInBase64) {
			      let _str = decode(item.contentInBase64)
			      _str = _str.replace(/\r\n/g, '\n')
			      if(_str.slice(0,1)=="\n"){
			        _str = _str.replace(/\n/, '')
			      }
			      arr.push({title: item.title, contentBase64: replaceNewlinesWithTwo(_str)})
			    }
			  })
			}
			setAnalyticalTextData(arr)
			const selfData = copyData?.doc?.entityFinancialData
			const diffData = copyData?.doc?.peerEntityFinancialData || copyData?.doc?.peerFinancialData || []
			let yearStr = ''
			if (isArray(selfData) && selfData?.length > 0) {
			  yearStr = selfData.slice(0,2).map((item) => {
				return item['年份']
			  }).join('/')
			  const newData = [...selfData, ...diffData].reduce((acc, cur) => {
				if (!acc[cur.entityId]) {
				  let obj = {}
				  obj[cur.entityId] = cur
				  obj[cur.entityId]['年份'] = yearStr
				   return Object.assign({}, acc, obj)
				} else {
				  acc[cur.entityId].financialIndicator = acc[cur.entityId].financialIndicator.map((item, index) => {
					item.indicatorValue = (Number(item.indicatorValue) + Number(cur.financialIndicator[index].indicatorValue)) / 2
					return item
				  })
				  return acc
				}
			  }, {})
			  getKeyIndicatorsColumns(deepCopy(newData), 'normal')
			  getKeyIndicatorsTableData(deepCopy(newData), 'normal')
			} else if(isObject(selfData)&& selfData.financialIndicator.length>0) {
			  yearStr = selfData['财务指标年度']
			  const result = [selfData];
			  const newData = [...result, ...diffData].reduce((acc, cur) => {
				// 没有 entityId 的情况下
				let obj = {}
				obj[cur['中文全称']] = cur
				obj[cur['中文全称']]['财务指标年度'] = yearStr
				return Object.assign({}, acc, obj)
			  }, {})
				getKeyIndicatorsColumns(deepCopy(newData), 'special')
				getKeyIndicatorsTableData(deepCopy(newData), 'special')   
			}
		}
	}, [props.data])
	const detailSomeData = (res) => {
	  let {
	    data: { doc },
	  } = res;
	  const result = {};
	  doc?.fiCharts?.anchorsChart?.data?.forEach((row) => {
	    const businessType = row[0]; // 获取业务类型
	    const columnValue = row[1]; // 获取第二列的值
	    const lastValue = parseInt(row[row.length - 1], 10); // 获取每行的最后一列的值并转为整数
	
	    // 如果result中还没有这个业务类型，则添加
	    if (!result[businessType]) {
	      result[businessType] = { columns: [], data: [] };
	    }
	
	    // 将columnValue添加到columns数组中（如果不存在的话）
	    if (!result[businessType].columns.includes(columnValue)) {
	      result[businessType].columns.push(columnValue);
	    }
	
	    // 将lastValue添加到data数组中
	    result[businessType].data.push(lastValue);
		
	  });
	  result['barColor'] =  doc?.fiCharts?.anchorsChart?.barColor
	  result['entityBarColor'] =  doc?.fiCharts?.anchorsChart?.entityBarColor
	  setCurrentAllData(result);
	};
	const getCompanyInfoData = (type, isFIType) => {
	  const FI_LEFT = [
	    {title:'评级基准', variable:'anchor'},
	    {title:'业务状况', variable:'businessProfile'},
	    {title:'资本与盈利性', variable:'capitalProfitability'},
	    {title:'风险状况', variable:'riskProfile'},
	    {title:'融资与流动性', variable:'financingLiquidity'},
	    {title:'补充调整', variable:'additionalAdjustment'},
	    {title:'个体信用质量', variable:'sacp'},
	    // {title:'支持方的信用质量', variable:'supportIcrOrSacp'},
	    {title:'外部支持重要性', variable:'externalSupportImportance'},
	    {title:'外部影响', variable:'finalNotching'},
	    {title:'未经调整集团信用质量（如适用）', variable:'unadjustedSacp'},
	    {title:'控股公司的特有风险因素（如适用）', variable:'fhcRisk'},
      {title:'主体信用质量', variable:'quality'}
	  ], CORP_LEFT = [
	    {title:'业务/经营状况', variable:'brp'},
	    {title:'行业名称', variable:'industryName'},
	    {title:'行业风险', variable:'industryRisk'},
	    {title:'竞争地位', variable:'competitivePosition'},
	    {title:'财务风险', variable:'frp'},
	    {title:'评级基准', variable:'anchor'},
	    {title:'个体信用质量', variable:'sacp'},
	    {title:'外部影响', variable:'externalImpact'},
	    {title:'主体信用质量', variable:'quality'},
	  ]
	  const renderArr = []
	  if(type=='left'){
	    (isFIType?FI_LEFT:CORP_LEFT).map(item => {
	      if(data?.doc?.[item.variable]) renderArr.push({ top: data.doc[item.variable], center: item.title })
	    })
	  } else {
	    if(data?.doc?.adjustmentItem) {
	      Object.keys(data.doc.adjustmentItem).map(key=> renderArr.push({ top: data.doc.adjustmentItem[key], center: key }))
	    }
	  }
	  return renderArr
	}
	// 关键指标数据处理
	const getKeyIndicatorsColumns = (data = [], type) => {
	    const newData = Object.values(data)
	    let columns = []
	    if (type === 'normal') {
	    columns = newData.map((item) => {
	      return {
	        title: item['年份'],
	        dataIndex: item.entityId,
	        key: item.entityId,
	      }
	    })
	  } else if (type ==='special') {
	    columns = newData.map((item) => {
	      return {
	        title: item['财务指标年度'],
	        dataIndex: item['中文全称'],
	        key: item['中文全称'],
	      }
	    })
	  }
	  columns.unshift({
	    title: "同行比较（两年平均）",  
	    dataIndex: 'comparative',  
	    key: 'comparative'
	  })
	  setColumns(columns)
	  }
	  const getKeyIndicatorsTableData = (data =[], type) => {
	    const newData = Object.values(data)
	    if (type === 'normal') {
	      setTableData(transformData(newData))
	    } else if (type ==='special') {
	      setTableData(transformData_special(newData))
	    }
	  }
	
	  // 转换函数
	const transformData = (input) => {
	  const companyNames = {};
	  const financialIndicators = [];
	
	  input.forEach(item => {
	      // 收集企业名称
	      companyNames[item.entityId] = item.企业名称;
	
	      // 收集财务指标
	      item.financialIndicator.forEach((indicator, index) => {
	          const indicatorIndex = financialIndicators.findIndex(fin => fin.comparative === indicator.indicatorName);
	          if (indicatorIndex === -1) {
	              financialIndicators.push({
	                  [item.entityId]: indicator.indicatorValue.toFixed(2),
	                  key: index + 1,
	                  comparative: indicator.indicatorName,
	              });
	          } else {
	              financialIndicators[indicatorIndex][item.entityId] = indicator.indicatorValue.toFixed(2);
	          }
	      });
	  });
	  
	  // 添加第一行企业名称
	  const firstRow = {
	      key: 0,
	      comparative: " ",
	      ...companyNames
	  };
	
	  financialIndicators.unshift(firstRow);
	  return financialIndicators;
	};
	  
	const transformData_special = (data) => {
	  // 初始化结果数组
	const result = [];
	
	// 添加银行名称行
	const bankNamesRow = {
	    key: 0,
	    comparative: " ",
	};
	
	// 添加银行数据
	data.forEach(bank => {
	    bankNamesRow[bank['中文全称']] = bank['中文全称'];
	});
	result.push(bankNamesRow);
	
	// 处理各个财务指标
	const indicators = data[0].financialIndicator.map(ind => ({
	  name: ind.indicatorName,
	  values: {}
	}));
	
	data.forEach(bank => {
	  bank.financialIndicator.forEach(ind => {
	      const indicatorEntry = indicators.find(i => i.name === ind.indicatorName);
	      if (indicatorEntry) {
	          indicatorEntry.values[bank['中文全称']] = ind.indicatorValue;
	      }
	  });
	});
	
	// 构建结果
	indicators.forEach((indicator, index) => {
	  const indicatorRow = {
	      key: index + 1,
	      comparative: indicator.name,
	  };
	  Object.entries(indicator.values).forEach(([bankName, value]) => {
	      indicatorRow[bankName] = value;
	  });
	  result.push(indicatorRow);
	});
	return result;
	}
	const exportPDF1 = async (title, target) => {
		let element = document.getElementById(target);
		if (element) {
			await html2pdf().from(element).set({
				margin: [8, 4, 8, 4],//页边距设置
				filename: `${title}.pdf`,//导出pdf标题
				image: {type: 'jpeg', quality: 1},
				html2canvas: {scale: 2, logging: true, dpi: 500, letterRendering: true,useCORS: true,},
				height:841.89,
				jsPDF: {
					unit: 'mm',
					format: [452.28,841.89],//页面大小，这里自适应了内容的高度
					orientation: 'portrait',// 页面方向，portrait: 纵向，landscape: 横向
					compressPDF: false, // 启用PDF内容压缩以适应页面
					putOnlyUsedFonts: true, // 仅包括使用的字体以减小文件大小
					autoPaging: true, // 启用自动分页
					precision: 16, // 浮点数的精度
					allowTaint:true,//允许跨域
					fontStyle: 'normal', // 或 'italic'，根据需要
					foreignObjectRendering:true,
				},
			}).save()
		}
	//   let ele = document.getElementById(target);
	//   // 根据dpi放大，防止图片模糊
	//   const scale = window.devicePixelRatio > 1 ? window.devicePixelRatio : 2;
	//   // 下载尺寸 a4 纸 比例
	//   let pdf = new jsPDF('p', 'pt', 'a4');
	//   let width = ele.offsetWidth;
	//   let height = ele.offsetHeight;
	//   console.log('height', height)
	//   console.log('aa', width, height, scale)
	
	//   const canvas = document.createElement('canvas');
	//   canvas.width = width * scale;
	//   canvas.height = height * scale;
	//   var contentWidth = canvas.width;
	//   var contentHeight = canvas.height;
	
	//   console.log('contentWidth', contentWidth, contentHeight)
	//   //一页pdf显示html页面生成的canvas高度;
	//   var pageHeight = contentWidth / 592.28 * 841.89;
	//   //未生成pdf的html页面高度
	//   var leftHeight = contentHeight;
	//   console.log('leftHeight', leftHeight)
	//   //页面偏移
	//   var position = 0;
	//   //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
	//   var imgWidth = 595.28;
	//   var imgHeight = 592.28 / contentWidth * contentHeight;
	//   const pdfCanvas = await html2canvas(ele, {
	//     useCORS: true,
	//     canvas,
	//     scale,
	//     width,
	//     height,
	//     x: 0,
	//     y: 0,
	//   });
	//   const imgDataUrl = pdfCanvas.toDataURL();
	
	//   if (height > 14400) { // 超出jspdf高度限制时
	//     const ratio = 14400 / height;
	//     // height = 14400;
	//     width = width * ratio;
	//   }
	
	//   // 缩放为 a4 大小  pdfpdf.internal.pageSize 获取当前pdf设定的宽高
	//   height = height * pdf.internal.pageSize.getWidth() / width;
	//   width = pdf.internal.pageSize.getWidth();
	//   if (leftHeight < pageHeight) {
	//     pdf.addImage(imgDataUrl, 'png', 0, 0, imgWidth, imgHeight);
	//   } else {    // 分页
	//     while (leftHeight > 0) {
	//       pdf.addImage(imgDataUrl, 'png', 0, position, imgWidth, imgHeight)
	//       leftHeight -= pageHeight;
	//       position -= 821.89;
	//       //避免添加空白页
	//       if (leftHeight > 0) {
	//         pdf.addPage();
	//       }
	//     }
	//   }
	//   // 导出下载 
	//   await pdf.save(`${title}.pdf`);
	}
//避免分页被截断
const exportPDF = (pdfDom, title) => {
    const A4_WIDTH = 592.28;
    const A4_HEIGHT = 841.89;
    // myLoading 自定义等待动画组件，实现导出事件的异步等待交互
    // dom的id。
    let target = document.getElementById(pdfDom);
    let pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT;
    // 获取分割dom，此处为class类名为item的dom
    let lableListID = target.children;
    // let lableListID = document.getElementsByClassName('item');
    // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
	let height = 0
	console.log(pageHeight,'pageHeight')
    for (let i = 0; i < lableListID.length; i++) {
        let multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight);
        if (isSplit(lableListID, i, multiple * pageHeight)) {
            let divParent = lableListID[i].parentNode; // 获取该div的父节点
            let newNode = document.createElement('div');
            newNode.className = 'emptyDiv';
			newNode.id = 'emptyDiv';
            newNode.style.background = '#fff';
            let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight);
			console.log(_H,lableListID[i],'_H')
            newNode.style.height = _H + 120 + 'px';
            newNode.style.width = '100%';
            let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
            // 判断兄弟节点是否存在
            if (next) {
                // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                divParent.insertBefore(newNode, next);
            } else {
                // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                divParent.appendChild(newNode);
            }
			height += lableListID[i].offsetHeight
        }
    }
    pdf(pdfDom, title);
}

// 判断是否需要添加空白div
const isSplit = (nodes, index, pageHeight) => {
    // 计算当前这块dom是否跨越了a4大小，以此分割
    if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
        return true;
    }
    return false;
}

const pdf = (pdfDom, title) => {
    // 避免出现浏览器滚动条导致的内容不全处理
    document.body.scrollTop = document.documentElement.scrollTop = 0
    //div内部滚动导致内容不全处理
    // document.getElementById('app').style.height = 'auto';
    setTimeout(() => {
        html2canvas(document.getElementById(pdfDom), {
            allowTaint: true,
            scale: 2,  // 按比例增加分辨率
            dpi: 500,  // 分辨率
            // height: document.getElementById('upload').scrollHeight,
            // windowHeight: document.getElementById('upload').scrollHeight
        }).then(canvas => {
            var contentWidth = canvas.width;
            var contentHeight = canvas.height;

            //一页pdf显示html页面生成的canvas高度;
            var pageHeight = contentWidth / 592.28 * 841.89;
            //未生成pdf的html页面高度
            var leftHeight = contentHeight;
            //页面偏移
            var position = 0;
            //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
            var imgWidth = 595.28;
            var imgHeight = 592.28 / contentWidth * contentHeight;

            var pageData = canvas.toDataURL('image/jpeg', 1.0);

            var pdf = new jsPDF('', 'pt', 'a4');

            //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
            //当内容未超过pdf一页显示的范围，无需分页
            if (leftHeight < pageHeight) {
                pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
            } else {
                while (leftHeight > 0) {
                    pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    //避免添加空白页
                    if (leftHeight > 0) {
                        pdf.addPage();
                    }
                }
            }
            pdf.save(`${title}.pdf`);
			if(document.getElementById('emptyDiv')) document.getElementById('emptyDiv').remove()
        })
    }, 300)
}
	const chartsData = (id, d, dataArr, sector, currentInfo) => {
		let allOptions = currentAllData[d]
		let options ={
			title: {
			  show: true, //false
			  text: d,
			  left: "center",
			  top: '5%',
			  textStyle: {
				color: "#000", //'red'，字体颜色
				fontWeight: "600", //'bold'(粗体) | 'bolder'(粗体) | 'lighter'(正常粗细) ，字体粗细
				fontFamily: "PingFangSC, PingFang SC", //'sans-serif' | 'serif' | 'monospace' | 'Arial' | 'Courier New'
				fontSize: 14, //字体大小
				lineHeight: 12, //字体行高
			  },
			  textAlign: "auto", //整体（包括 text 和 subtext）的水平对齐
			  textVerticalAlign: "auto", //整体（包括 text 和 subtext）的垂直对齐
			  padding: 0, //[5,10] | [ 5,6, 7, 8] ,标题内边距
			  left: "45%", //'5' | '5%'，title 组件离容器左侧的距离
			},
			grid: {
			  // left: "1%",
			  bottom:25,
			},
			tooltip: {},
			xAxis: {
			  type: "category",
			  data: allOptions?.columns,
			},
			yAxis: {
			  show: false,
			},
			series: [
			  {
				data: allOptions?.data,
				type: "bar",
				itemStyle:{
					color: function(record){
						let text = ''
						if(d === '业务状况') text = props.data?.doc.businessProfile 
						if(d === '融资与流动性') text = props.data?.doc.capitalProfitability
						if(d === '资本与盈利性') text = props.data?.doc.financingLiquidity
						if(d === '风险状况') text = props.data?.doc.riskProfile 
						return text === record.name?currentAllData.entityBarColor:currentAllData.barColor
					}
				}
			  },
			],
		};
		return options
    };
	return <div className={s.container} id="pdfContainer">
		<div className={s.download} onClick={()=>{exportPDF1(props.data?.entityName,'pdfContainer')}} data-html2canvas-ignore="true">
			<img src={downloadImg} alt="" style={{width:'80px',height:'80px'}} />
		</div>
		<div className={s.table}>
			<div className={[s.tr,s.tableTitle].join(' ')}>
				<div className={s.td}>公司名称</div>
				<div className={s.td}>主体信用质量</div>
				<div className={s.td}>行业分类</div>
				<div className={s.td}>日期</div>
				<div className={s.td}>分析师</div>
				<div className={s.td}>联系方式</div>
			</div>
			<div className={s.tr}>
				<div className={s.td}>{props.data?.entityName}</div>
				<div className={s.td}>{props.data?.quality}</div>
				<div className={s.td}>{props.data?.doc.industrySubcategory}</div>
				<div className={s.td}>{props.data?.reportDate}</div>
				<div className={s.td}>{props.data?.doc.analystName}</div>
				<div className={s.td}>{props.data?.doc.analystEmail}</div>
			</div>
		</div>
		<div className={s.detailContent}>
			<div className={s.assessment}>
				<div className={s.title}>
					<span>分项评估</span>
					<span>分析结果</span>
				</div>
				<div className={s.content}>
					<div className={s.left}>
						{
							listData.map((item,index)=>{
								return <div key={index} className={s.line}>
									<span>{item.center}：</span>
									<span>{item.top}</span>
								</div>
							})
						}
					</div>
				</div>
				<div style={{textAlign:'center',fontSize: '16px',fontWeight: 'bold',marginTop:'20px'}}>a+评级基准金融机构分项评估要素在子级调整情况分布示意</div>
				<div style={{display:'flex',flexWrap: 'wrap'}}>
					<ReactEcharts
					  ref={getEchartsRef}
					  option={chartsData(3,'业务状况',undefined,undefined,0)}
					  notMerge={true}
					  lazyUpdate={true}
					  style={{ width:'50%',height: '220px',marginTop:'20px' }}
					/>
					<ReactEcharts
					  ref={getEchartsRef}
					  option={chartsData(3,'融资与流动性',undefined,undefined,0)}
					  notMerge={true}
					  lazyUpdate={true}
					  style={{ width:'50%',height: '220px',marginTop:'20px' }}
					/>
					<ReactEcharts
					  ref={getEchartsRef}
					  option={chartsData(3,'资本与盈利性',undefined,undefined,0)}
					  notMerge={true}
					  lazyUpdate={true}
					  style={{ width:'50%',height: '220px',marginTop:'20px' }}
					/>
					<ReactEcharts
					  ref={getEchartsRef}
					  option={chartsData(3,'风险状况',undefined,undefined,0)}
					  notMerge={true}
					  lazyUpdate={true}
					  style={{ width:'50%',height: '220px',marginTop:'20px' }}
					/>
				</div>
				<ReactEcharts
				  ref={getEchartsRef}
				  option={getChartOptions(1)}
				  notMerge={true}
				  lazyUpdate={true}
				  style={{ width:'100%',height: '220px',marginTop:'20px' }}
				/>
				<ReactEcharts
				  ref={getEchartsRef}
				  option={getChartOptions(2)}
				  notMerge={true}
				  lazyUpdate={true}
				  style={{ width:'100%',height: '220px',marginTop:'20px' }}
				/>
				<div style={{marginTop:'10px',padding: '0 10px 10px'}}>
					<div className={s.desc}>
						注*：本报告中所呈现的潜在信用质量分布是我们根据公开信息，通过案头分析所得出对于信用质量的初步观点。 标普信评未与任何机构进行访谈或其他任何形式的互动沟通， 也未通过标普信评的信用评级流程例如信用评审委员会加以审核与处理本报告所呈现的潜在信用质量分布（标普信评公开披露了评级结果的机构除外）。 本报告中表达的观点不可也不应被表述为信用评级，也不应被视为任何机构的最终评级结果的表示。
					</div>
				</div>
			</div>
			<div className={s.indicators}>
				<div className={s.title} style={{display:'flex'}}>关键指标</div>
				<div style={{padding: '0 10px'}}>
					{
						analyticalTextData.map((item,index)=>{
							return <div key={index} style={{marginTop:'10px',marginBottom:'12px'}}>
								<span style={{fontWeight:'bold'}}>{item.title}：</span>
								<span dangerouslySetInnerHTML={{ __html: item.contentBase64 }} style={{whiteSpace: 'pre-wrap'}}></span>
							</div>
						})
					}
				</div>
			</div>
		</div>
		<div className={s.table} style={{marginTop:'10px'}}>
			<div className={[s.tr,s.tableTitle].join(' ')}>
				{
					columns.map((item,index)=>{
						return <div className={s.td} key={index}>{item.title}</div>
					})
				}
			</div>
			{
				tableData.map((item,index)=>{
					return <div className={s.tr} key={index}>
						{
							columns.map((demo,idx)=>{
								return <div className={s.td} key={idx}>{item[demo.dataIndex]}</div>
							})
						}
					</div>
				})
			}
			<div className={s.tips}>资料来源：Wind，银行公开信息，标普信评</div>
		</div>
		{
			props.data?.doc.industryCategory === '银行' && <div className={s.table} style={{marginTop:'10px'}}>
				<div className={[s.tr,s.tableTitle].join(' ')}>
					<div className={s.td}>公司名称</div>
					<div className={s.td}>主体信用质量</div>
					<div className={s.td}>行业分类</div>
					<div className={s.td}>日期</div>
					<div className={s.td}>分析师</div>
					<div className={s.td}>联系方式</div>
				</div>
				<div className={s.tr}>
					<div className={s.td}>{props.data?.entityName}</div>
					<div className={s.td}>{props.data?.quality}</div>
					<div className={s.td}>{props.data?.doc.industrySubcategory}</div>
					<div className={s.td}>{props.data?.reportDate}</div>
					<div className={s.td}>{props.data?.doc.analystName}</div>
					<div className={s.td}>{props.data?.doc.analystEmail}</div>
				</div>
			</div>
		}
		{
			props.data?.doc.industryCategory === '银行' && <div style={{marginTop:'10px'}} className={s.bankBox}>
				<div className={s.bankTitle}>各项关键指标分布图</div>
				<div style={{display:'flex',flexWrap:'wrap'}}>
					<div style={{width:'50%'}}>
						<ReactEcharts
						  ref={getEchartsRef}
						  option={getChartOptionsDetail(1,'中国主要商业银行客户存款分布')}
						  notMerge={true}
						  lazyUpdate={true}
						  style={{ width:'100%',height: '320px',marginTop:'20px' }}
						/>
						<div style={{width:'100%',textAlign:'center'}}>存款总额(亿元)</div>
					</div>
					<div style={{width:'50%'}}>
						<ReactEcharts
						  ref={getEchartsRef}
						  option={getChartOptionsDetail(2,'中国主要商业银行经调整的一级资本充足率分布')}
						  notMerge={true}
						  lazyUpdate={true}
						  style={{ width:'100%',height: '320px',marginTop:'20px' }}
						/>
						<div style={{width:'100%',textAlign:'center'}}>轻度压力情景下经调整的以及资本充足率(%)</div>
					</div>
					<div style={{width:'50%'}}>
						<ReactEcharts
						  ref={getEchartsRef}
						  option={getChartOptionsDetail(3,'中国主要商业银行净息差分布')}
						  notMerge={true}
						  lazyUpdate={true}
						  style={{ width:'100%',height: '320px',marginTop:'20px' }}
						/>
						<div style={{width:'100%',textAlign:'center'}}>净息差(%)</div>
					</div>
					<div style={{width:'50%'}}>
						<ReactEcharts
						  ref={getEchartsRef}
						  option={getChartOptionsDetail(4,'中国主要商业银行信用成本分布')}
						  notMerge={true}
						  lazyUpdate={true}
						  style={{ width:'100%',height: '320px',marginTop:'20px' }}
						/>
						<div style={{width:'100%',textAlign:'center'}}>当期拨备/平均贷款总额(%)</div>
					</div>
					<div style={{width:'50%'}}>
						<ReactEcharts
						  ref={getEchartsRef}
						  option={getChartOptionsDetail(5,'中国主要商业银行拨备覆盖情况分布')}
						  notMerge={true}
						  lazyUpdate={true}
						  style={{ width:'100%',height: '320px',marginTop:'20px' }}
						/>
						<div style={{width:'100%',textAlign:'center'}}>贷款减值准备/不良及关注类贷款(%)</div>
					</div>
					<div style={{width:'50%'}}>
						<ReactEcharts
						  ref={getEchartsRef}
						  option={getChartOptionsDetail(6,'中国主要商业银行3月期同业存单利差分布')}
						  notMerge={true}
						  lazyUpdate={true}
						  style={{ width:'100%',height: '320px',marginTop:'20px' }}
						/>
						<div style={{width:'100%',textAlign:'center'}}>2022年1月至2023年12月末3月期同业存单利差(bp)</div>
					</div>
					<div style={{width:'50%'}}>
						<ReactEcharts
						  ref={getEchartsRef}
						  option={getChartOptionsDetail(7,'主要商业银行潜在个体信用质量测评结果分布')}
						  notMerge={true}
						  lazyUpdate={true}
						  style={{ width:'100%',height: '320px',marginTop:'20px' }}
						/>
						<div style={{width:'100%',textAlign:'center'}}>潜在个体信用质量测评结果</div>
					</div>
					<div style={{width:'50%'}}>
						<ReactEcharts
						  ref={getEchartsRef}
						  option={getChartOptionsDetail(8,'主要商业银行潜在主体信用质量测评结果分布')}
						  notMerge={true}
						  lazyUpdate={true}
						  style={{ width:'100%',height: '320px',marginTop:'20px' }}
						/>
						<div style={{width:'100%',textAlign:'center'}}>潜在主体信用质量测评结果</div>
					</div>
				</div>
				<div style={{marginTop:'10px',padding:'10px'}}>
					<div>注*：本报告中所呈现的潜在信用质量分布是我们根据公开信息，通过案头分析所得出对于信用质量的初步观点。 标普信评未与任何机构进行访谈或其他任何形式的互动沟通， 也未通过标普信评的信用评级流程例如信用评审委员会加以审核与处理本报告所呈现的潜在信用质量分布（标普信评公开披露了评级结果的机构除外）。 本报告中表达的观点不可也不应被表述为信用评级，也不应被视为任何机构的最终评级结果的表示。</div><br/>
					<div>资料来源：Wind，银行公开信息，标普信评。</div>
				</div>
			</div>
		}
		<div className={s.bqTips}>
			<div>©版权所有2024标普信用评级（中国）有限公司。保留所有权利。</div><br/>
			<div>本非公开信用分析基于贵司与标普信用评级（中国）有限公司（简称“标普信评”）签订的委托协议，和/或基于贵司和标普信评关联方就通过该关联方平台获取本非公开信用分析所签订的协议及其中的条款和条件而提供。本非公开信用分析仅限在条款和条件中明确允许的情况下进行传播。本非公开信用分析结果仅基于公开渠道收集的资料，受评对象未参与且不涉及受评对象的内部文件(除非该等受评对象的内部文件由贵司依据委托协议提供给标普信评)和/或与受评对象管理层的互动沟通。我们根据我们的政策和流程确定的标准使用了来源可靠的资料，但不保证所用资料的正确性、充分性和完整性。本非公开信用分析结果在中国境内不得用于任何监管目的，亦不得用于任何融资活动，包括但不限于任何债券发行或者资金筹集活动。本非公开信用分析结果报告不得作为任何债券或证券发行文件的一部分被披露，不得在募集说明书、其他债券发行文件中或任何披露文件中被援引或提及，不得为投资人或其他任何人士所依赖。本非公开信用分析结果不可等同于或表述为标普信评受委托授予的信用评级，也不得视为标普全球评级观点。标普信评对授予、更新或撤销任何非公开信用分析结果拥有绝对酌情决定权。</div><br/>
			<div>标普信评拥有上述内容（包括评级、信用相关的分析和数据、估值、模型、软件或其他应用或其中的输出）或其任何部分（简称“内容”）的版权和 或其他相关知识产权。未经标普信评或其关联方的事先书面许可，严禁以任何形式或方式修改、逆向工程、复制或发布任何内容，或将任何内容存储在数据库或检索系统中。内容不得用于任何非法或未经授权的目的。标普信评和任何第三方供应商，以及其董事、管理人员、股东、员工或代理人（统称“标普方”）均不保证内容的准确性、完整性、及时性或可用性。标普方不对由任何原因导致的错误或遗漏（疏忽或其他）、因使用内容而获得的结果、或用户输入数据的安全性或维护该等数据承担责任。内容基于“现状”提供。标普方特此声明不做出任何明示或默示保证，包括但不限于对适销性或特定用途或使用目的的保证，以及对不存在漏洞、不存在软件错误或缺陷，以及内容的功能将不会中断或内容将与任何软件或硬件配置兼容等方面的保证。在任何情形下，标普方将不对任何人就与使用任何内容相关的任何直接、间接、附带、惩罚、补偿、惩戒、特殊或后续的损害、费用、开支、律师费或损失（包括且不限于收入损失、利润损失以及因疏忽造成的机会成本和损失）承担责任，即使标普方已经知道发生类似损害的可能性。</div><br/>
			<div>信用相关的分析和其他分析（包括评级和内容中的陈述）是截至发表之日的意见陈述，而非事实陈述。标普信评的意见、分析和评级确认决策（如下所述）并非且不应被视为购买、持有或出售任何证券或作出任何投资决策的建议，也不涉及任何证券的适合性。在发布后，标普信评不承担更新（不论以任何形式或格式）发布内容的义务。在进行投资和其他业务决策时，不应依赖内容，内容也无法取代用户、其管理层、员工、顾问和或客户的技能、判断和经验。标普信评不作为受托人或投资顾问，除非其注册为该类机构。虽然标普信评从其认为可靠的渠道获取信息，但标普信评不审计其获得的信息，也不承担相关的尽职调查义务或独立验证义务。与评级相关的出版物可能由于各种原因发布，这些原因不一定取决于评级委员会的行动，例如发布定期更新的信用评级和相关分析。</div><br/>
			<div>标普信评并不属于标普全球评级身为国家认可统计评级机构（NRSRO）的联属企业。标普信评根据在中国专用的评级等级体系授予评级，所授予的评级是标普信评对于债务人相对于中国境内其他发行人的整体资信或对特定债务的偿债能力的意见，并提供在中国境内信用风险的排序。标普信评所授予的评级并非根据全球评级等级体系所授予的评级，不可也不应被视为或不实地表述为全球评级等级体系下授予的评级，或者作为全球评级等级体系下授予的评级而加以依赖。标普方不为违反本段使用标普信评的评级所产生的任何损失负责。</div><br/>
			<div>如果监管机构允许评级机构在一个司法辖区内因某些监管目的承认在另一个司法辖区发布的评级，标普信评保留随时自行决定授予、撤销或中止此类承认的权利。标普信评特此声明不对因授予、撤销或中止承认而产生的任何责任以及宣称因此而产生的任何损害负责。</div><br/>
			<div>标普信评将其不同业务单位的活动保持分离，以保持相应活动的独立性和客观性。因此，标普信评的某些业务单位可能拥有其他业务单位所没有的信息。标普信评制定了政策和程序，以确保对各个分析过程中相关的特定非公开信息予以保密。</div><br/>
			<div>标普信评可能从其评级和特定分析活动中获得报酬，报酬一般由证券发行人或承销人或者债务人支付。标普信评保留发布其意见和分析的权利。标普信评的公开评级和分析公布在其网站上 <a href="www.spgchinaratings.cn">www.spgchinaratings.cn</a> 并且可以通过其他方式发布，包括但不限于标普信评出版物和第三方转销商。</div><br/>
			<div>非公开且保密：本非公开信用分析报告的有效性截至报告出具日期。标普信评自该日期起未对该非公开信用分析进行审阅，当前分析或存在差异。</div>
		</div>
	</div>
}

export default OldDetail