import React from "react";
import style from "./index.module.scss";
import AnalysisResults from "@/assets/icons/analysisResults.png";

const IntroContent = (props) => {
  const { data = {}, newData = {} } = props;
  const industryItems = [
    { label: "同行业主体", valueVar: "overPercent" },
    { label: "行业风险", valueVar: "industriesRisk" }
    ],
    publicSentiment = [
      { label: "相关舆情点评", valueVar: "publicOpinionNum" },
      { label: "半年内主体信用质量变动", valueVar: "qualityChange" },
      { label: "预警监控", valueVar: "warningInfo" },
    ];

  return (
    <div className={style.introContent_container}>
      <div className={style.introContent_innerContainer}>
        <div className={style.innerContainerTop}>
          更新时间：{data?.reportDate}
        </div>
        <div className={style.innerContainerBody}>
          <span
            className={style.innerContainerBodyName}
            style={{ display: "inline-block" }}
          >
            {data?.entityName}
          </span>
          <div className={style.introContent_row}>
            <div className={style.introContent_title}>行业分析 <span className={style.industryName}>{data?.doc?.industryName}</span></div>
          </div>
          <div className={style.introContent_items}>
            {industryItems.map((item, index) => {
              return (
                <div className={style.introContent_items_item} key={index} style={{
                  display: item.valueVar == 'industriesRisk' && !newData?.[item.valueVar] ? 'none' : 'flex',
                  flexDirection: 'column',
                }}>
                  {
                    item.valueVar !== 'overPercent' ?
                    <div
                    className={style.introContent_items_label}
                    style={{
                      // width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.label}
                  </div>
                  :
                  null
                  }
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className={style.introContent_items_value} style={{marginTop: item.valueVar == 'overPercent' ? "0px" : "4px"}}>
                      {
                        item.valueVar == 'overPercent' ? <div style={{ marginBottom: "5px", color:"rgba(0, 0, 0, 0.65)",marginRight:"3px"}}>主体信用质量优于</div> : null
                      }
                      {newData?.[item.valueVar]
                        ? item.valueVar == 'overPercent' ? parseInt(newData?.[item.valueVar]).toFixed(0) + "%" : newData?.[item.valueVar]
                        : "-"}
                      {
                        item.valueVar == 'overPercent' ?
                        <span style={{color:"rgba(0, 0, 0, 0.65)",marginLeft:"3px"}}>{item.label}</span>
                        :
                        null
                      }
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className={style.industryName}>{props?.isFIType ? data?.doc?.industryCategory : data?.doc?.industryName}</div> */}
          </div>
          <div className={style.introContent_row}>
            <div className={style.introContent_title}>风险舆情</div>
          </div>
          <div className={style.introContent_items}>
            {publicSentiment.map((item, index) => {
              return (
                <div style={{width: item.label == "相关舆情点评" ? "27%" : item.label == "半年内主体信用质量变动" ? "39%" : "33%"}} className={style.introContent_items_item} key={index}>
                  <div
                    className={style.introContent_items_label}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.label}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <div className={style.introContent_items_value}>
                      {newData?.[item.valueVar]
                        ? newData?.[item.valueVar]
                        : item.label == "预警监控"
                        ? "暂无信息"
                        : "-"}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.innerContainerModal}>
          <div>
            <div>主体信用质量</div>
            <span>{data?.quality}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroContent;
