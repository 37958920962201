import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Modal } from 'antd'
import style from './index.module.scss'
import './defaultStyle.scss'
import customer from '../../assets/images/customer.png';

/**
 * @description: 弹窗自定义
 * @param {*} buttonNode 【DomNode: 弹窗按钮】
 * @param {*} openDialog 【Function: buttonNode没有时的打开弹窗函数】
 * @param {*} title 【String/Dom: 弹窗标题】
 * @param {*} onOk 【Function: 弹窗确认回调函数】
 * @param {*} onCancel 【Function: 弹窗关闭回调函数】
 * @param {*} content 【Node: 弹窗内容】
 * @param {*} hasFooter 【Boolean: 弹窗是否有footer操作按钮】
 * @param {*} footer 【Array[DomNode]: 弹窗自定义操作键配置】
 * @param {*} helpContent 【Boolean: 是否显示帮助内容】
 * @param {*} callback 【Function: 弹窗自定义回调函数】
 * @param {*} beforeClose 【Function: 弹窗打开前的回调】
 * @param {*} afterClose 【Function: 弹窗完全关闭的回调】
 * @param {*} updateContentState 【Boolean: 是否随内容变动更新元素 *默认false】
 * @param {*} extendClassName 【String: 扩展类名】
 * @param {*} cancelTxt 【String: 取消文本】
 * @param {*} confirmTxt 【String: 确认文本】
 * @param {*} maskClosable 【Boolean: 点击蒙层是否允许关闭】
 * @param {*} cancleClosable 【Boolean: 点击取消是否直接关闭弹窗】
 * @param {*} modalWidth 【Number: 弹窗宽度, 默认600】
 * @param {*} closable 【Boolean: 是否显示右上角的关闭按钮】
 * @param {*} renderChildren 【Boolean: 是否加载children内容】
 * @return {*}
 */
const ModalItem = (props) => {
  const { buttonNode, title, onOk, content, onCancel, hasFooter=true, footer=[], helpContent=true, modalWidth=600, renderChildren=false,
    cancelTxt='取 消', confirmTxt='确 认', maskClosable=true, visibleState, beforeClose, afterClose, callback,
    updateContentState=false, extendClassName='', cancleClosable=true, closable=true, disabled } = props
  const [visible ,setVisible] = useState(props?.buttonNode?false:visibleState)
  const [contentInfo ,setContentInfo] = useState(content)
  /**
   * @description: 默认操作按钮回调
   * @param {*} type confirm/cancel 操作类型
   * @return {*}
   */
  const defaultOperation = (type) => {
    if(type == 'confirm'){
      onOk && onOk().then(ok => ok&&setVisible(!visible))
    } else {
      if(cancleClosable||type == 'close') setVisible(!visible)
      onCancel && onCancel(type)
    }
  }
  useImperativeHandle(props.cRef, ()=>({
    setVisible, setContentInfo
  }))
  useEffect(()=>{
    if(!buttonNode) {
      setVisible(visibleState)
      if(visibleState) return setContentInfo(content)
        else {
          afterClose && afterClose()
          setContentInfo(null)
        }
    }
  },[visibleState])
  useEffect(()=>{
    if(buttonNode) beforeClose && beforeClose()
  },[visible])
  useEffect(()=>{
    if(content&&updateContentState) setContentInfo(content)
  },[content])
  const operationList = footer.length==0&&hasFooter?{
    onOk: ()=> defaultOperation('confirm'),
    footer: [
      <div className={style.footer_wrapper} key='defaultFooter'>
        <div className={style.footer_cancle} onClick={()=>defaultOperation('cancle')}>{cancelTxt}</div>
        <div className={style.footer_confirm} onClick={()=>defaultOperation('confirm')}>{confirmTxt}</div>
      </div>
    ]
  }:{ footer }
  return <div className={style.modal_wrapper}>
    <div onClick={()=> !disabled && setVisible(!visible)}>{ buttonNode }</div>
    <Modal open={visible} {...operationList} forceRender={true} width={modalWidth} maskClosable={maskClosable}
      onCancel={()=> defaultOperation('close')} className={`modalContainer ${extendClassName}`} closable={closable}>
      <div className={style.modal_content}>
        { title?
          <div className={style.modalContainer__title}>{title}</div>
          :null
        }
        {renderChildren?props.children:contentInfo}
        { helpContent
            ?<div className={style.modalContainer__customer}>
              <div>如有验证或平台使用问题，请联系工作人员：</div>  
              <div className={style.modalContainer__customer_scan}>扫码添加微信</div>
              <img className={style.modalContainer__customer_img} src={customer} alt=''/>
              <div className={style.modalContainer__customer_mobile}>联系电话：17610137961</div>
            </div>
          :null
        }
      </div>
    </Modal>
  </div>
}

export default ModalItem