import service from "@/utils/request"

const common = {
  // 获取邮箱验证码
  getVerifyCodeEmail(params) {
    return service.request({ url: "/user/sendEmail", method: "get", params })
  },
  // 验证邮箱验证码
  checkVerifyCodeEmail(params) {
    return service.request({ url: "/user/checkEmailCode", method: "get", params })
  },
  // 获取手机验证码
  getVerifyCodeMobile(params) {
    return service.request({ url: "/user/sms/code", method: "get", params })
  },
  // 账号登录
  loginAccount(data) {
    return service.request({ url: "/user/token", method: "post", data })
  },
  // 账号注册
  registerAccount(data) {
    return service.request({ url: "/user/register", method: "post", data })
  },
};

export default common;