import service from "@/utils/request"

const options = {
  // 获取行业列表
  getIndustryOptions(params) {
    return service.request({ url: "/company/getIndustryList", method: "get", params })
  },
  // 获取企业类型列表
  getCompanyTypeOptions(params) {
    return service.request({ url: "/company/getTypeList", method: "get", params })
  },
};

export default options;