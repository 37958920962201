import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useCallback,
} from "react";
import {
  Layout,
  Form,
  Row,
  Input,
  Menu,
  Button,
  Space,
  Avatar,
  Image,
  Dropdown,
  message,
  Affix,
  Drawer,
} from "antd";
import { DownOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "@/api";
import { useSearchParams } from "react-router-dom";
import { debounceEvent, getDeviceType } from "@/utils";
import actions from "@/redux/actions";
import Style from "./index.module.scss";
import CustomModal from "../CustomModal/index";
import ModalItem from "@/components/modalItem";
import FooterContainer from "../Layout/footer";
import avatar_icon from "../../assets/icons/avatar_icon.png";
import header_icon from "../../assets/icons/header_icon.png";
import modal_icon from "../../assets/icons/modal_icon.png";
import emailCode from "../../assets/icons/emailCode.png";
const { Header, Footer, Content } = Layout;

const headerMenu = [
  { label: "首页", key: "/home" },
  { label: "非公开信用分析", key: "/creditAnalysis" },
  {
    label: "风险舆情",
    key: "/riskPublicOpinion",
    children: [
      { label: "市场风险监控", key: "/riskMonitor" },
      { label: "评级变动", key: "/ratingChanges" },
      { label: "舆情与风险提示通讯", key: "/riskWarning" },
    ],
  },
  { label: "行业分析", key: "riskInfo" },
  { label: "专题研究", key: "/industrialResearch" },
  { label: "预警管理", key: "/earlyWarning" },
  { label: "信用风险仪表盘", key: "/creditRisks" },
  { label: "市场活动", key: "/marketingActivities" },
  {
    label: "后台管理",
    key: "/adminManages",
    children: [
      { label: "用户列表", key: "/userManage"},
      { label: "子账号管理", key: "/subAccountManage",}
    ],
    accessUserLevel: [3,4]
  },
  { label: "增加模块", key: "2" },
];
const LayoutContainer = () => {
  const [form] = Form.useForm();
  const modalRef = useRef();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // 获取查询参数
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [menuListPaths, setMenuListPaths] = useState([]);
  const [current, setCurrent] = useState(
    sessionStorage.getItem("currentMenu") || "/home"
  );
  const [visibleState, setVisibleState] = useState(false); //弹窗状态
  const [mobileTipVisibleState, setMobileTipVisibleState] = useState(false); //弹窗状态
  const [timeCode, setTimeCode] = useState(60); //手机验证码计时时间
  const [openDrawerStatus, setOpenDrawerStatus] = useState(false); //菜单是否展示
  const user_login_status = useSelector((state) => state.user_login_status);
  const user_account_info = useSelector((state) => state.user_account_info);
  const platform_device = useSelector((state) => state.platform_device);
  const showMobile = platform_device == "Mobile";

  useEffect(() => {
    const token = localStorage.getItem("pca_token");
    if (token) dispatch(actions.setUserLoginStatus(true));
    else logout();
    if (menuListPaths.length > 0 && !menuListPaths.includes(current))
      navigate("/home");
  }, [current]);
  useEffect(() => {
    if (current !== "/home" && showMobile) {
      setCurrent("/home");
      navigate("/home");
      sessionStorage.setItem("currentMenu", "/home");
    }
  }, [showMobile]);
  /**
   * @description: 获取设备类型
   * @return {*}
   */
  const getDeviceTypeEvent = () => {
    const device = getDeviceType();
    dispatch(actions.setPlatformDeviceType(device));
  };
  useEffect(() => {
    let user_info = localStorage.getItem("pca_user");
    if (user_info && !user_account_info.name)
      dispatch(actions.setUserAccountInfo(JSON.parse(user_info)));
    user_info = null;
    if (searchParams.size > 0) {
      if (current == "/home") {
        const user_resource = searchParams.get("user_resource");
        if (user_resource) navigate(`/home?user_resource=${user_resource}`);
      }
    }
    window.addEventListener("resize", getDeviceTypeEvent);
    getDeviceTypeEvent();
  }, []);
  useEffect(() => {
    const _headerMenu = JSON.parse(JSON.stringify(headerMenu));
    const paths = getMenuPaths(headerMenu, user_account_info?.level ?? 1);
    const items = _headerMenu.map((_) => {
      if (
        !_.accessUserLevel ||
        _.accessUserLevel.includes(user_account_info.level)
      ) {
        if (_.accessUserLevel) delete _.accessUserLevel;
        return _;
      }
    });
	const is_admin = user_account_info.level === 3
	const is_accountManager = user_account_info.level === 4
	if(!is_admin) {
		items[5].key = '2'
		items[6].key = '2'
    items.splice(3,1)
	}
  if (is_accountManager || is_admin) {
    const arr = items.find((item) => item.label === '后台管理')
    if(arr) {
      if (is_accountManager) arr.children = arr.children.map((i) => i.label === '子账号管理' && i)
      if (is_admin) arr.children = arr.children.map((i) => i.label === '用户列表' && i)
    }
  }
    setMenuList(items);
    setMenuListPaths(paths);
  }, [user_account_info]);
  /**
   * @description: 获取所有可访问路由
   * @param {*} arrs
   * @param {*} level
   * @return {*}
   */
  const getMenuPaths = (arrs, level) => {
    let paths = [];
    arrs.map((item) => {
      if (
        !item.accessUserLevel ||
        item.accessUserLevel.includes(user_account_info.level)
      ) {
        paths.push(item.key);
        if (item.children) paths.push(...getMenuPaths(item.children, level));
      }
    });
    return paths;
  };
  const logout = () => {
    navigate("/home");
    setCurrent("/home");
    dispatch(actions.setUserLoginStatus(false));
    dispatch(actions.setUserAccountInfo({}));
    sessionStorage.clear();
    localStorage.removeItem("pca_token");
    localStorage.removeItem("pca_user");
    if (platform_device == "Mobile") setOpenDrawerStatus(false);
  };
  /**
   * @description: 表单确认
   */
  const handleConfirm = async () => {
    let validate = await form.validateFields().catch((err) => console.log(err));
    if (validate) {
      const _formInfo = await form.getFieldsValue();
      let result = await api.checkVerifyCodeEmail({
        ..._formInfo,
        userId: user_account_info.userId,
      });
      if (result?.code == 200) {
        let user_info = JSON.parse(localStorage.getItem("pca_user"));
        user_info.emailStatus = true;
        localStorage.setItem("pca_user", JSON.stringify(user_info));
        dispatch(actions.setUserAccountInfo(user_info));
        message.success("邮箱认证成功");
        setVisibleState(false);
        user_info = null;
      }
      validate = null;
      result = null;
    }
  };
  /**
   * @description: 取消/关闭弹窗
   */
  const handleCancle = () => {
    form.resetFields();
    setVisibleState(false);
  };
  const dropdownMenu = [
    {
      label: <a onClick={logout}>退出</a>,
      key: "1",
    },
  ];
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const menuClick = async (e) => {
    if (platform_device == "Mobile") setOpenDrawerStatus(false);
    if (!user_login_status) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return message.warning("请先登录账号");
    }
    if (!user_account_info.emailStatus && e.key !== "/home") {
      setVisibleState(true);
      if ([0, 60].includes(timeCode)) {
        await getVerCode();
      }
      return;
    }
    if (showMobile && e.key !== "/home") {
      setMobileTipVisibleState(true);
      return;
    }
    if (
      e.key === "2" ||
      (user_account_info.level == 1 &&
        ["/earlyWarning", "/creditRisks"].includes(e.key)) 
    ) {
      return showModal();
    }
    setCurrent(e.key);
    navigate(e.key);
    sessionStorage.setItem("currentMenu", e.key);
  };
  /**
   * @description: 发送验证码
   * @param {*}
   * @return {*}
   */
  const getVerCode = async () => {
    let _timeCode = 60;
    const verCode = await api.getVerifyCodeEmail({
      userId: user_account_info.userId,
      type: 1,
    });
    if (verCode?.code !== 200) return;
    message.success(verCode?.data);
    const timer = setInterval(() => {
      _timeCode--;
      setTimeCode(_timeCode);
      modalRef?.current?.setTimeCode?.(_timeCode);
      if (_timeCode === 0) {
        clearInterval(timer);
      }
    }, 1000);
  };
  /**
   * @description: 弹窗内容
   */
  const ModalConfigContent = (props) => {
    const [_timeCode, set_TimeCode] = useState(props?.timeCode || 0); //手机验证码计时时间
    useImperativeHandle(props.cRef, () => ({
      setTimeCode: set_TimeCode,
    }));
    const debouncedHandleClick = useCallback(
      debounceEvent(getVerCode, 1000),
      []
    );
    return (
      <div className={Style.modalContainer__wrapper}>
        <img
          className={Style.modalContainer__headerIcon}
          src={modal_icon}
          alt=""
        />
        <div className={Style.modalContainer__mainTitle}>请先完成邮箱认证</div>
        <div className={Style.modalContainer__subTitle}>
          <span>一封认证邮件已发送</span>
          {user_account_info.email}
        </div>
        <div className={Style.modalContainer__form}>
          <Form wrapperCol={24} form={form} name="verifyEmail">
            <Row
              style={{
                width: "100%",
                lineHeight: "initial",
                position: "relative",
                height: 40,
              }}
              key="code"
            >
              <Form.Item
                name="code"
                className={`${Style.verifyCode__input} searchFormItem`}
                required
              >
                <Input
                  prefix={<img src={emailCode} width={21} alt="" />}
                  placeholder="请输入公司邮箱验证码"
                  suffix={
                    <div className={Style.verifyCode__btn}>
                      {_timeCode !== 0 ? (
                        <div
                          className={`${Style.verifyCode__btn_txt} ${Style.verfied}`}
                        >
                          {_timeCode} 秒后重新获取
                        </div>
                      ) : (
                        <div
                          className={Style.verifyCode__btn_txt}
                          onClick={debouncedHandleClick}
                        >
                          获取验证码
                        </div>
                      )}
                    </div>
                  }
                />
              </Form.Item>
            </Row>
          </Form>
        </div>
      </div>
    );
  };
  const getStyle = () => {
    return (
      current == "/home" ||
      window.location.href.indexOf("/queryView") != -1 ||
      window.location.href.indexOf("/riskInfo") != -1
    );
  };
  return (
    // 这里是整个页面的布局，包括头部、内容、脚部
    <Layout
      style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}
    >
      {/* 头部 */}
      <Affix
        offsetTop={0}
        style={{ position: "sticky", left: 0, top: 0, zIndex: 100 }}
      >
        <Header className={Style["header"]}>
          <img src={header_icon} alt="logo" />
          {showMobile ? (
            <div>
              <div className={Style.menuDrawer}>
                {openDrawerStatus ? (
                  <CloseOutlined onClick={() => setOpenDrawerStatus(false)} />
                ) : (
                  <MenuOutlined onClick={() => setOpenDrawerStatus(true)} />
                )}
              </div>
              <Drawer
                placement="right"
                open={openDrawerStatus}
                width={200}
                zIndex={99}
                className="mobilePlatform__drawer"
                onClose={() => setOpenDrawerStatus(false)}
              >
                <Menu
                  onClick={menuClick}
                  selectedKeys={current}
                  style={{
                    maxWidth: "200px",
                    border: 0,
                    height: "calc(100% - 70px)",
                    overflow: "auto",
                  }}
                  defaultSelectedKeys={[current]}
                  theme="light"
                  mode="inline"
                  items={menuList}
                ></Menu>
                {user_login_status ? (
                  <div className={Style.logoutBtn} onClick={logout}>
                    退出登录
                  </div>
                ) : null}
              </Drawer>
            </div>
          ) : (
            <Menu
              onClick={menuClick}
              selectedKeys={current}
              style={{ maxWidth: "60%" }}
              defaultSelectedKeys={[current]}
              theme="dark"
              mode="horizontal"
              items={menuList}
            ></Menu>
          )}
          <Space className={Style["header-right"]} align="center">
            <Button
              type="primary"
              onClick={showModal}
              className={Style.technicalSupport}
            >
              技术支持
            </Button>
            {user_login_status ? (
              <>
                {!showMobile ? (
                  <Avatar icon={<Image preview={false} src={avatar_icon} />} />
                ) : null}
                <Dropdown menu={{ items: dropdownMenu }}>
                  <Space style={{ cursor: "pointer" }}>
                    {!showMobile ? (
                      <>
                        <p className={Style["name"]}>
                          {user_account_info.name}
                        </p>
                        <DownOutlined
                          style={{
                            fontSize: "12px",
                            color: "rgba(255,255,255,0.85)",
                          }}
                        />
                      </>
                    ) : (
                      <Avatar
                        icon={<Image preview={false} src={avatar_icon} />}
                      />
                    )}
                  </Space>
                </Dropdown>
              </>
            ) : null}
          </Space>
        </Header>
      </Affix>
      <Content className={getStyle() ? "" : Style["main-content"]}>
        {/* 内容部分由 Outlet 组件渲染，子路由将在这里渲染 */}
        <Outlet />
      </Content>
      <Footer style={{ padding: 0 }}>
        <FooterContainer />
      </Footer>
      <ModalItem
        visibleState={visibleState}
        content={<ModalConfigContent cRef={modalRef} timeCode={timeCode} />}
        onOk={handleConfirm}
        onCancel={handleCancle}
        closable={!showMobile}
      />
      <ModalItem
        visibleState={mobileTipVisibleState}
        title="温馨提示"
        closable={false}
        hasFooter={false}
        footer={
          <div className={Style.openMobileTip__btnContainer}>
            <div
              className={Style.openMobileTip__option}
              onClick={() => setMobileTipVisibleState(false)}
            >
              好的
            </div>
          </div>
        }
        onCancel={() => setMobileTipVisibleState(false)}
        content={
          <div className={Style.openMobileTip__wrapper}>
            <div>为了您的最佳体验，请使用电脑端浏览器访问该页面功能</div>
          </div>
        }
      />
      <CustomModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        wechatQRCode={true}
      />
    </Layout>
  );
};

export default LayoutContainer;
