import * as actionTypes from '@/redux/type'

export default {
  /**
   * @description: 设置设备类型
   * @return {*}
   */
  setPlatformDeviceType: (data) => ({
    type: actionTypes.default.SET_PLATFORM_DEVICE_TYPE,
    payload: data,
  })
}
