import * as actionTypes from '@/redux/type'

export default {
  /**
   * @description: 设置用户登录状态
   * @return {*}
   */
  setUserLoginStatus: (data) => ({
    type: actionTypes.default.SET_USER_LOGIN_STATUS,
    payload: data,
  }),
  /**
   * @description: 设置用户身份信息
   * @return {*}
   */
  setUserAccountInfo: (data) => ({
    type: actionTypes.default.SET_USER_ACCOUNT_INFO,
    payload: data,
  })
}
