import React, { useEffect, useState } from "react";
import api from "@/api";
import { deepCopy } from "@/utils";
import style from "./index.module.scss";
import { useSearchParams } from "react-router-dom";
import IntroContent from "./comps/introContent";
import ChartProcess from "./comps/chartProcess";
import ChartAnalysis from "./comps/chartAnalysis";
import MenuContentItem from "./comps/menuContentItem";
import ContainerBox from "./comps/containerBox";
import TabsDetail from "./comps/tabsDetail";
import NewsList from "./comps/newsList";
import SubjectCreditBarChart from "./comps/SubjectCreditBarChart";
import IndicatorsAndComparisonTableList from "./comps/IndicatorsAndComparisonTableList";
import FinancialAnalusis from "./comps/financialAnalusis";
import {
  detailNumberValue,
  getLastYearMonthArray,
  comparTwoData,
} from "@/utils";
let isSmillScreen = false
const QueryView = (props) => {
  const [companyDetail, setCompanyDetail] = useState({});
  const [newData, setNewData] = useState({});
  const [isFIType, setIsFIType] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuWidth, setMenuWidth] = useState(0);
  const [searchParams] = useSearchParams(); // 获取查询参数
  const companyId = searchParams.get("id");
  const industriesId = searchParams.get("industries");
  const qualityId = searchParams.get("quality");
  const [flowData, setFlowData] = useState([]);
  const [chartsOptions, setChartsOptions] = useState({});
  const [chartKey, setChartKey] = useState(true);
  const [flowLoading, setFlowLoading] = useState(false);
  const [qualityData, setQualityData] = useState([]);
  const [newlistData, setnewListData] = useState([]);
  const [currentAllData, setCurrentAllData] = useState({});
  const [currentRardaData, setCurrentRardaData] = useState([]);
  const [XData, setXData] = useState([]);
  const [currentChangeIndexArr, setCurrentChangeIndexArr] = useState([]);
  const [chooseZb,setChooseZb] = useState(false)
  const [currentFlowName,setCurrentFlowName] = useState('个体信用质量')
  const [isBasicHy,setIsBasicHy] = useState(false)
  const [ pdfData, setPdfData ] = useState()
  const getDetailPdf = async () => {
	  const result = await api.getRecordDetail({ companyId })
	  setPdfData(result.data)
  }
  /**
   * @description: 获取公司详情
   * @return {*}
   */
  const getCompanyDetailInfo = async () => {
    const result = await api.getCreditAnalysisDetail({ companyId });
    if (result?.code == 200) {
      setCompanyDetail(result.data);
      setIsFIType(result.data?.sector == "金融机构");
      setLoading(false);
      const list = result.data?.sector == "金融机构"
        ? (result.data?.doc?.entityFinancialData ?? {},
          result.data?.doc?.peerFinancialData ?? []).length == 0
          ? []
          : [...(result.data?.doc?.peerFinancialData ?? [])]
        : (result.data?.doc?.peerEntityFinancialData ?? []).length == 0
        ? []
        : [
            ...(result.data?.doc?.entityFinancialData ?? []),
            ...(result.data?.doc?.peerEntityFinancialData ?? []),
          ];
      detailSomeData(result);
      getFlowData(result);
      getQualityDistribution(false,result.data?.sector);
    }
  };
  const detailSomeData = (res) => {
    let {
      data: { doc },
    } = res;
    const result = {};
    doc?.fiCharts?.anchorsChart?.data?.forEach((row) => {
      const businessType = row[0]; // 获取业务类型
      const columnValue = row[1]; // 获取第二列的值
      const lastValue = parseInt(row[row.length - 1], 10); // 获取每行的最后一列的值并转为整数

      // 如果result中还没有这个业务类型，则添加
      if (!result[businessType]) {
        result[businessType] = { columns: [], data: [] };
      }

      // 将columnValue添加到columns数组中（如果不存在的话）
      if (!result[businessType].columns.includes(columnValue)) {
        result[businessType].columns.push(columnValue);
      }

      // 将lastValue添加到data数组中
      result[businessType].data.push(lastValue);
    });
    setCurrentAllData(result);
  };
  const getAdvancedInfo = async () => {
    const result = await api.getCompanyAdvancedInfo({
      companyId,
      industries: industriesId,
      quality: qualityId,
    });
    if (result?.code == 200) {
      setNewData(result?.data);
    }
  };
  const getQualityChange = async () => {
    const result = await api.getCompanyQualityChange({ companyId });
    if (result?.code == 200) {
      let dateArr = getLastYearMonthArray();
      let defaultNum = null;
      if (result?.data && result?.data?.length > 0) {
        defaultNum = result?.data[0].quality;
      }
      let arr1 = dateArr.map(
        (time) =>
          result?.data?.find((item) => item.lastQualityTime == time)?.quality ||
          defaultNum
      );
      let arr = dateArr.map(
        (time) => {
          return {
            quality:  result?.data?.find((item) => item.lastQualityTime == time)?.quality ||
            defaultNum,
            isReport:result?.data?.find((item) => item.lastQualityTime == time)?.isReport ||
            false,
          }
        }
        
      );
      let changeIndexArr = comparTwoData(arr);
      setXData(arr1);
      setCurrentChangeIndexArr([...changeIndexArr]);
    }
  };
  const getQualityDistribution = async (flag = false,sector) => {
    setIsBasicHy(flag)
    const result = await api.getCompanyQualityDistribution(
      flag ? { industries: industriesId,sector } : {sector}
    );
    if (result?.code == 200) {
      setQualityData(result?.data);
    }
  };
  const getOpinionList = async () => {
    const result = await api.getCompanyOpinionList({
      companyId,
      current: 1,
      size: 20,
    });
    if (result?.code == 200) {
      setnewListData(result?.data?.records);
    }
  };
  const menus_creditDetail = {
    menus: [
		{
		  title: "PCA报告",
		  key: "pca_pdf",
		  href: "#pca_pdf",
		},
      {
        title: "PCA框架",
        key: "pca_credit",
        href: "#pca_credit",
        children: [{ title: "分析概述", key: "overview", href: "#overview" }],
      },
      {
        title: "风险舆情",
        key: "opinion",
        href: "#opinion",
        children: [
          { title: "相关舆情点评", key: "opinion_review", href: "#opinion1" },
          { title: "半年内主体信用质量变动", key: "rating_changes", href: "#opinion2" },
        ],
      },
      {
        title: "信用质量分布",
        key: "mass_distribution",
        href: "#mass_distribution",
      },
      {
        title: "财务分析",
        key: "financial_analysis",
        href: "#financial_analysis",
      },
      // { title: "压力测试", key: "stress_testing" , href: "#stress_testing"},
      { title: "同业比较", key: "comparison", href: "#comparison" },
    ],
    defaultActiveKey: "overview",
    defaultOpenSubKey: ["pca_credit", "opinion"],
  };

  useEffect(() => {
    isSmillScreen = window.innerHeight <= 780
    setFlowLoading(true);
	getDetailPdf()
    getCompanyDetailInfo();
    getAdvancedInfo();
    getQualityChange();
    getOpinionList();
  }, []);

  const getFlowData = (res) => {
    let dataArr = [];
    let {
      data: { doc },
    } = res;
	console.log(doc,'doc')
    if (res.data?.sector == "金融机构") {
      setFlowData([
        {
          id: 0,
          x: 0,
          y: 32,
          name: "经济风险",
          info: doc?.brp ? doc?.brp : "",
          old_info:doc?.brp ? doc?.brp : "",
		  new_info:'经济风险反映了我们对国内信贷环境和市场竞争动态的基本判断。',
          linkInfo: [2],
          children: [],
        },
        {
          id: 1,
          x: 0,
          y: 138,
          name: "行业风险",
          info: doc?.frp ? doc?.frp : "",
          old_info: doc?.frp ? doc?.frp : "",
		  new_info:'行业风险反映了我们对于国内金融行业制度和监管层面的稳定性的基本判断。',
          linkInfo: [2],
          children: [],
        },
        {
          id: 2,
          x: 160,
          y: 88,
          name: "评级基准",
          info: doc?.anchor ? doc?.anchor : "",
          old_info: doc?.anchor ? doc?.anchor : "",
		  new_info:'评级基准反映了对一类金融子行业平均个体信用质量的评估，是我们评估金融机构个体信用质量的起点。',
          linkInfo: [3, 4, 5, 6, 10],
          children: [0, 1],
        },
        {
          id: 3,
          x: 320,
          y: 0,
          name: "业务状况",
          info: detailNumberValue(
            doc?.businessProfile ? doc?.businessProfile : ""
          ),
          old_info:doc?.businessProfile ? doc?.businessProfile : "",
		  new_info:'业务状况反映了金融机构的业务经营实力，主要从以下三个方面进行评估：业务稳定性，集中度或多样性，以及管理和战略等；通常以市场份额作为定量评估的起点。',
		  linkInfo: [7],
          children: [0, 1, 2],
        },
        {
          id: 4,
          x: 320,
          y: 54,
          name: "资本与盈利性",
          info: detailNumberValue(
            doc?.capitalProfitability ? doc?.capitalProfitability : ""
          ),
          old_info: doc?.capitalProfitability ? doc?.capitalProfitability: "",
		  new_info:'资本与盈利性反映了金融机构在经济严重下滑时吸收损失的能力，是前瞻性判断；通常以经标普信评调整后的一级资本充足率作为核心定量指标。',
          linkInfo: [7],
          children: [0, 1, 2],
        },
        {
          id: 5,
          x: 320,
          y: 108,
          name: "风险状况",
          info: detailNumberValue(doc?.riskProfile ? doc?.riskProfile : ""),
          old_info:doc?.riskProfile ? doc?.riskProfile : "",
		  new_info:'风险状况反映了在资本与盈利性评估中尚未充分考虑的风险。通常以标普信评评估的问题贷款比例作为核心定量指标。',
          linkInfo: [7],
          children: [0, 1, 2],
        },
        {
          id: 6,
          x: 320,
          y: 162,
          name: "融资与流动性",
          info: detailNumberValue(doc?.financingLiquidity ? doc?.financingLiquidity : ""),
          old_info:doc?.financingLiquidity ? doc?.financingLiquidity : "",
		  new_info:'融资与流动性分别评估融资和流动性，然后综合考虑二者对个体信用质量的整体影响。融资分析关注中长期融资实力和稳定性。流动性分析考虑机构在不利的市场条件和经济状况下管理流动性需求的能力。',
          linkInfo: [7],
          children: [0, 1, 2],
        },
        {
          id: 7,
          x: 480,
          y: 88,
          name: "个体信用质量",
          info: detailNumberValue(doc?.sacp ? doc?.sacp : ""),
          old_info:doc?.sacp ? doc?.sacp : "",
		  new_info:'个体信用质量反映了机构自身的内在信用状况，不包括机构在危机情况下可能得到的外部支持。个体信用质量综合反映了机构业务状况、资本与盈利性、风险状况，以及融资与流动性四个方面的考虑。',
          linkInfo: [8, 9],
          children: [0, 1, 2, 3, 4, 5, 6],
        },
		{
		  id: 8,
		  x: 640,
		  y: 88,
		  name: "外部影响",
		  info: detailNumberValue(
		    doc.finalNotching ? doc.finalNotching : ""
		  ),
		  old_info:doc.finalNotching ? doc.finalNotching : "",
		  new_info:'外部支持反映机构在危机情况下可能得到的集团或政府特殊支持。',
		  linkInfo: [9],
		  children: [0, 1, 2, 3, 4, 5, 6, 7],
		},
		{
		  id: 9,
		  x: 800,
		  y: 88,
		  name: "主体信用质量",
		  info: detailNumberValue(res?.data ? res?.data?.quality : ""),
		  old_info:res?.data ? res?.data?.quality : "",
		  new_info:'主体信用质量是个体信用质量结合外部支持，得出机构最终的主体信用质量',
		  linkInfo: [],
		  children: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
		},
		{
		  id: 10,
		  x: 320,
		  y: 216,
		  name: "补充调整",
		  info: detailNumberValue(doc?.additionalAdjustment ? doc?.additionalAdjustment : ""),
		  old_info:doc?.additionalAdjustment ? doc?.additionalAdjustment : "",
		  new_info:'补充调整反映了在前述分析中没有包括或没有充分反映的其他信用要素，补充调整通常通过同业比较来实现，目的是确保个体信用质量能够更全面地反映我们对该机构的信用观点。',
		  linkInfo: [7],
		  children: [0, 1, 2, 3, 4, 5, 6,7],
		},
      ]);
      dataArr.push(
        detailNumberValue(doc?.businessProfile ? doc?.businessProfile : ""),
        detailNumberValue(
          doc?.capitalProfitability ? doc?.capitalProfitability : ""
        ),
        detailNumberValue(
          doc?.financingLiquidity ? doc?.financingLiquidity : ""
        ),
        detailNumberValue(doc?.riskProfile ? doc?.riskProfile : "")
      );
      setCurrentRardaData(dataArr);
    } else {
      setFlowData([
        {
          id: 0,
          x: 0,
          y: 0,
          name: "行业风险",
          info: detailNumberValue(doc?.industryRisk ? doc?.industryRisk : ""),
          old_info:doc?.industryRisk ? doc?.industryRisk : "",
		  new_info:'行业风险反映了我们对于国内金融行业制度和监管层面的稳定性的基本判断。',
          linkInfo: [3],
          children: [],
        },
        {
          id: 1,
          x: 0,
          y: 89,
          name: "竞争地位",
          info: detailNumberValue(
            doc?.competitivePosition ? doc?.competitivePosition : ""
          ),
          old_info:doc?.competitivePosition ? doc?.competitivePosition : "",
		  new_info:'',
          linkInfo: [3],
          children: [],
        },
        {
          id: 2,
          x: 0,
          y: 178,
          name: "现金流/财务杠杆",
          info: detailNumberValue(doc?.frp ? doc?.frp : ""),
          old_info:doc?.frp ? doc?.frp : "",
		  new_info:'',
          linkInfo: [4],
          children: [],
        },
        {
          id: 3,
          x: 155,
          y: 45,
          name: "业务状况",
          info: detailNumberValue(doc?.brp ? doc?.brp : ""),
          old_info:doc?.brp ? doc?.brp : "",
		  new_info:'业务状况反映了金融机构的业务经营实力，主要从以下三个方面进行评估：业务稳定性，集中度或多样性，以及管理和战略等；通常以市场份额作为定量评估的起点。',
          linkInfo: [5],
          children: [0, 1],
        },
        {
          id: 4,
          x: 155,
          y: 178,
          name: "财务风险",
          info: detailNumberValue(doc?.frp ? doc?.frp : ""),
          old_info:doc?.frp ? doc?.frp : "",
		  new_info:'',
          linkInfo: [5],
          children: [2],
        },
        {
          id: 5,
          x: 310,
          y: 106,
          name: "评级基准",
          info: detailNumberValue(doc?.anchor ? doc?.anchor : "",),
          old_info:doc?.anchor ? doc?.anchor : "",
		  new_info:'评级基准反映了对一类金融子行业平均个体信用质量的评估，是我们评估金融机构个体信用质量的起点。',
          linkInfo: [6, 7, 8, 9, 10, 14],
          children: [0, 1, 2, 3, 4],
        },
        {
          id: 6,
          x: 465,
          y: 0,
          name: "多元化程度",
          info: detailNumberValue(
            doc?.adjustmentItem ? doc?.adjustmentItem["多元化"] : ""
          ),
          old_info:doc?.adjustmentItem ? doc?.adjustmentItem["多元化"] : "",
		  new_info:'',
          linkInfo: [11],
          children: [0, 1, 2, 3, 4, 5],
        },
        {
          id: 7,
          x: 465,
          y: 54,
          name: "资本结构",
          info: detailNumberValue(
            doc?.adjustmentItem ? doc?.adjustmentItem["资本结构"] : ""
          ),
          old_info: doc?.adjustmentItem ? doc?.adjustmentItem["资本结构"] : "",
		  new_info:'',
          linkInfo: [11],
          children: [0, 1, 2, 3, 4, 5, 6],
        },
        {
          id: 8,
          x: 465,
          y: 108,
          name: "财务政策",
          info: detailNumberValue(
            doc?.adjustmentItem ? doc?.adjustmentItem["财务政策"] : ""
          ),
          old_info:doc?.adjustmentItem ? doc?.adjustmentItem["财务政策"] : "",
		  new_info:'',
          linkInfo: [11],
          children: [0, 1, 2, 3, 4, 5, 6, 7],
        },
        {
          id: 9,
          x: 465,
          y: 162,
          name: "流动性",
          info: detailNumberValue(
            doc?.adjustmentItem ? doc?.adjustmentItem["流动性"] : ""
          ),
          old_info:doc?.adjustmentItem ? doc?.adjustmentItem["流动性"] : "",
		  new_info:'',
          linkInfo: [11],
          children: [0, 1, 2, 3, 4, 5, 6, 7, 8],
        },
        {
          id: 10,
          x: 465,
          y: 216,
          name: "管理与治理",
          info: detailNumberValue(
            doc?.adjustmentItem ? doc.adjustmentItem["管理与治理"] : ""
          ),
          old_info:doc?.adjustmentItem ? doc.adjustmentItem["管理与治理"] : "",
		  new_info:'',
          linkInfo: [11],
          children: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        },
		
        {
          id: 11,
          x: 620,
          y: 108,
          name: "个体信用质量",
          info: detailNumberValue(doc?.sacp ? doc?.sacp : ""),
          old_info:doc?.sacp ? doc?.sacp : "",
		  new_info:'个体信用质量反映了机构自身的内在信用状况，不包括机构在危机情况下可能得到的外部支持。个体信用质量综合反映了机构业务状况、资本与盈利性、风险状况，以及融资与流动性四个方面的考虑。',
          linkInfo: [12],
          children: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        },
        {
          id: 12,
          x: 775,
          y: 108,
          name: "主体信用质量",
          info: detailNumberValue(res?.data ? res?.data?.quality : ""),
          old_info:res?.data ? res?.data?.quality : "",
		  new_info:'主体信用质量是个体信用质量结合外部支持，得出机构最终的主体信用质量',
          linkInfo: [],
          children: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15],
        },
		{
		  id: 13,
		  x: 620,
		  y: 162,
		  name: "外部影响",
		  info: detailNumberValue(
		    doc.externalImpact ? doc.externalImpact : ""
		  ),
		  old_info:doc.externalImpact ? doc.externalImpact : "",
		  new_info:'外部支持反映机构在危机情况下可能得到的集团或政府特殊支持。',
		  linkInfo: [12],
		  children: [],
		},
		{
		  id: 14,
		  x: 465,
		  y: 270,
		  name: "调整项",
		  info: detailNumberValue(doc?.adjustmentSum ? doc?.adjustmentSum : ""),
		  old_info:doc?.adjustmentSum ? doc?.adjustmentSum : "",
		  new_info:'',
		  linkInfo: [11],
		  children: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		},
      ]);
      dataArr.push(
        detailNumberValue(doc?.industryRisk ? doc?.industryRisk : ""),
        detailNumberValue(
          doc?.competitivePosition ? doc?.competitivePosition : ""
        ),
        detailNumberValue(doc?.brp ? doc?.brp : ""),
        detailNumberValue(doc?.frp ? doc?.frp : "")
      );
      setCurrentRardaData(dataArr);
    }
    chartsData(null, null, dataArr, res.data.sector);
    setFlowLoading(false);
  };

  const chartsData = (id, d, dataArr, sector, currentInfo) => {
    let isJRFlag = false;
    if (sector && sector == "金融机构") {
      isJRFlag = true;
    } else if (isFIType) {
      isJRFlag = true;
    }
    let currentChartKey = !chartKey;
    let flag = false;
    Object.keys(currentAllData).map((ele) => {
      if (ele == d) {
        flag = true;
      }
    });
    if (id == null || flag == false) {
      let final_arr = dataArr ? dataArr : currentRardaData;
      let is_final_arr = isJRFlag ? final_arr.map((number) =>  number) : final_arr.map((number) =>  -number);
	  console.log(is_final_arr,'is_final_arr')
      let options = {
        tooltip: {
          className: style.echarts_outer,
          confine: true,
          enterable: false, //鼠标是否可以移动到tooltip区域内
          formatter: (a, b, c) => {
            let {
              data: { name, value },
            } = a;
            let str = "";
            value.map((item, index) => {
              str += `<div style="display:flex;align-items:center;justify-content:space-between">
                 <div style="display:flex;align-items:center;margin-right:6px">
                   <div style="width:16px;height:16px;border-radius:2px;background:#5B8FF9;margin-right:8px"></div>
                   <div style="font-family: PingFangSC, PingFang SC;font-weight: 400;font-size: 12px;color: #000000;">${
                     name[index]
                   }</div>
                 </div>
                 <div style="font-family: HelveticaNeue, HelveticaNeue;font-weight: 500;font-size: 12px;color: #000000;">${
                   isJRFlag ? item : -item
                 }</div>
                </div>`;
            });
            return ` <div style="width:100%;height:100%">
            ${str}
              </div>`;
          },
        },
        radar: {
          indicator: isJRFlag
            ? [
                {
                  name: "业务状况",
                  min: -3,
                  max: 3,
                  axisLabel: {
                    show: true,
                    inside: true,
                    fontSize: 12,
                    formatter: (value, a) => {
                      return value;
                    },
                    color: "rgba(0, 0, 0, 0.45)",
                    // showMaxLabel: false, //不显示最大值，即外圈不显示数字30
                    showMinLabel: true, //显示最小数字，即中心点显示0
                  },
                },
                { name: "资本与盈利性", min: -5, max: 2 },
                { name: "融资流动性", min: -3, max: 2 },
                { name: "风险状况", min: -5, max: 2 },
              ]
            : [
                {
                  name: "行业风险",
                  max: -1,
                  min: -5,
                  axisLabel: {
                    show: true,
                    inside: true,
                    fontSize: 12,
                    formatter: (value, a) => {
                      return -value;
                    },
                    color: "rgba(0, 0, 0, 0.45)",
                    // showMaxLabel: false, //不显示最大值，即外圈不显示数字30
                    showMinLabel: true, //显示最小数字，即中心点显示0
                  },
                },
                { name: "竞争地位", max: -1, min: -6 },
                { name: "业务状况", max: -1, min: -6 },
                { name: "财务风险", max: -1, min: -6 },
              ],
          splitNumber: isJRFlag ? 4 : 5, // 这里设置了5个刻度环
          radius:isSmillScreen ? ["0%","65%"] : ['0%','75%'],
          nameGap:10,
          axisLabel: {
            margin: 3,
            verticalAlign: "top",
            align: "right",
          },
          axisName:{
            fontSize:isSmillScreen ? 9 : 12
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: "rgba(255,255,255,0)", // 图表背景的颜色
            },
          },
        },
        series: [
          {
            type: "radar",
            areaStyle: {
              normal: {
                width: 1,
                opacity: 0.2,
              },
            },
            data: [
              {
                value: is_final_arr,
                name: isJRFlag
                ?["业务状况","资本与盈利性","融资流动性","风险状况"] : ["行业风险", "竞争地位", "业务状况", "财务风险"],
                itemStyle: {
                  normal: {
                    color: "rgba(91,143,249,.9)",
                  },
                },
              },
            ],
          },
        ],
      };
      setChartsOptions(options);
      setChooseZb(false)
      setCurrentFlowName('个体信用质量')
    } else {
      let ccolumns = [...currentAllData[d]?.columns];
      let carr = deepCopy(currentAllData[d]?.data);
      let findIndex = ccolumns.findIndex((item) => item == currentInfo);
      carr[findIndex] = {
        value: carr[findIndex],
        itemStyle: { color: " #33D695" },
      };
      let options = {
        title: {
          show: true, //false
          text: "行业内主体数量", //主标题文本
          textStyle: {
            color: "#B0B5B9", //'red'，字体颜色
            fontWeight: "400", //'bold'(粗体) | 'bolder'(粗体) | 'lighter'(正常粗细) ，字体粗细
            fontFamily: "PingFangSC, PingFang SC", //'sans-serif' | 'serif' | 'monospace' | 'Arial' | 'Courier New'
            fontSize: 12, //字体大小
            lineHeight: 12, //字体行高
          },
          textAlign: "auto", //整体（包括 text 和 subtext）的水平对齐
          textVerticalAlign: "auto", //整体（包括 text 和 subtext）的垂直对齐
          padding: 0, //[5,10] | [ 5,6, 7, 8] ,标题内边距
          left: "2.5%", //'5' | '5%'，title 组件离容器左侧的距离
          top: "30", //title 组件离容器上侧的距离
        },
        grid: {
          left: "14%",
          bottom:25,
        },
        tooltip: {},
        xAxis: {
          type: "category",
          data: ccolumns,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: carr,
            type: "bar",
          },
        ],
      };
      setChartsOptions(options);
      setChartKey(currentChartKey);
      setChooseZb(true)
      setCurrentFlowName(d)
    }
  };

  const changeCurrentSelectId = (f, d, a, b, currentInfo) => {
    chartsData(f, d, undefined, undefined, currentInfo);
  };

  return (
    <>
		<div className={style.queryView__wrapper}>
		  {/* 顶部主内容 */}
		  <div className={style.queryView__topMainContent}>
		    <IntroContent isFIType={isFIType} data={companyDetail} newData={newData} />
		    <ChartProcess
		      isFIType={isFIType}
		      chooseZb={chooseZb}
		      flowLoading={flowLoading}
		      changeCurrentSelectId={changeCurrentSelectId}
		      data={companyDetail}
		      flowData={flowData}
		    />
		    <ChartAnalysis
		      chartKey={chartKey}
		      flowLoading={flowLoading}
		      flowName={currentFlowName}
		      options={chartsOptions}
		      changeCurrentSelectId={changeCurrentSelectId}
		      data={companyDetail}
		      chooseZb={chooseZb}
		    />
		  </div>
		  <div className={style.queryView__bodyContent}>
		    <MenuContentItem
		      {...menus_creditDetail}
		      handleMenuWidth={setMenuWidth}
		    />
		    <div
		      className={style.bodyContent_wrapper}
		      id="bodyContent_wrapper"
		      style={{ width: `calc(100% - ${menuWidth}px)` }}
		    >
		      <ContainerBox
		        id="pca_credit"
		        loading={loading}
		        title={
		          <>
		            <div className={style.bodyContent_container} id="overview">
		              <span>PCA分析概述</span>
		              <div className={style.bodyContent_overview}>
		                <div>评级基准</div> {companyDetail?.doc?.anchor}
		              </div>
		            </div>
		          </>
		        }
		      >
		        <TabsDetail data={companyDetail} />
		      </ContainerBox>
		      <ContainerBox id="opinion" loading={loading}>
		        <div className={style.bodyContent_varyContainer}>
		          <div className={style.bodyContent_newsContainer} id="opinion1">
		            <NewsList
		              isFIType={isFIType}
		              XData={XData}
		              currentChangeIndexArr={currentChangeIndexArr}
		              type="1"
		              data={companyDetail}
		              newData={newlistData}
		              title="风险舆情"
		            />
		          </div>
		          <div
		            className={style.bodyContent_ratingContainer}
		            id="opinion2"
		          ></div>
		        </div>
		      </ContainerBox>
		      <ContainerBox
		        id="mass_distribution"
		        isQualityArea={true}
		        title={isBasicHy ? `${isFIType ? companyDetail?.doc?.industryCategory : companyDetail?.doc?.industryName}行业主体信用质量分布` : isFIType ? "约 450 家金融机构主体信用质量分布" : "约 4500 家样本企业主体信用质量分布"} 
		        loading={loading}
		      >
		        <SubjectCreditBarChart
		          basicData={companyDetail}
		          newData={qualityData}
		          isFIType={isFIType}
		          changeSubjectInfo={getQualityDistribution}
		        />
		      </ContainerBox>
		      <ContainerBox id="financial_analysis" loading={loading}>
		        <FinancialAnalusis basicData={companyDetail} id={companyId} />
		      </ContainerBox>
		      <ContainerBox id="comparison" loading={loading} title="同业比较">
		        {/* <IndicatorsAndComparisonBarChart
		          basicData={companyDetail}
		          data={indicatorsAndComparisonBarChartData}
		        /> */}
		        <IndicatorsAndComparisonTableList data={companyDetail} />
		      </ContainerBox>
		    </div>
		  </div>
		</div>
    </>
  );
};

export default QueryView;
