import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import { deepCopy, isArray, isObject, formatNumberWithCommas } from '@/utils'
import Style from './IndicatorsAndComparisonTableList.module.scss'

 function IndicatorsAndComparisonTableList({data = {}}) {
  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    console.log(data, '====> IndicatorsAndComparisonTableList')
    const copyData = deepCopy(data)
    const selfData = copyData?.doc?.entityFinancialData
    const diffData = copyData?.doc?.peerEntityFinancialData || copyData?.doc?.peerFinancialData || []
    let yearStr = ''
    if (isArray(selfData) && selfData?.length > 0) {
      yearStr = selfData.slice(0,2).map((item) => {
        return item['年份']
      }).join('/')
      const newData = [...selfData, ...diffData].reduce((acc, cur) => {
        if (!acc[cur.entityId]) {
          let obj = {}
          obj[cur.entityId] = cur
          obj[cur.entityId]['年份'] = yearStr
           return Object.assign({}, acc, obj)
        } else {
          acc[cur.entityId].financialIndicator = acc[cur.entityId].financialIndicator.map((item, index) => {
            item.indicatorValue = (Number(item.indicatorValue) + Number(cur.financialIndicator[index].indicatorValue)) / 2
            return item
          })
          return acc
        }
      }, {})
      getKeyIndicatorsColumns(deepCopy(newData), 'normal')
      getKeyIndicatorsTableData(deepCopy(newData), 'normal')
    } else if(isObject(selfData)&& selfData.financialIndicator.length>0) {
      yearStr = selfData['财务指标年度']
      const result = [selfData];
      const newData = [...result, ...diffData].reduce((acc, cur) => {
        // 没有 entityId 的情况下
        let obj = {}
        obj[cur['中文全称']] = cur
        obj[cur['中文全称']]['财务指标年度'] = yearStr
        return Object.assign({}, acc, obj)
      }, {})
        getKeyIndicatorsColumns(deepCopy(newData), 'special')
        getKeyIndicatorsTableData(deepCopy(newData), 'special')   
    }
  }, [])
  const getKeyIndicatorsColumns = (data = [], type) => {
    const newData = Object.values(data)
    let columns = []
    if (type === 'normal') {
    columns = newData.map((item) => {
      return {
        title: item['年份'],
        dataIndex: item.entityId,
        key: item.entityId,
      }
    })
  } else if (type ==='special') {
    columns = newData.map((item) => {
      return {
        title: item['财务指标年度'],
        dataIndex: item['中文全称'],
        key: item['中文全称'],
      }
    })
  }
  columns.unshift({
    title: "同行比较（两年平均）",  
    dataIndex: 'comparative',  
    key: 'comparative'
  })
  console.log(columns, '====> columns111')
  setColumns(columns)
  }
  const getKeyIndicatorsTableData = (data =[], type) => {
    const newData = Object.values(data)
    if (type === 'normal') {
      setTableData(transformData(newData))
    } else if (type ==='special') {
      setTableData(transformData_special(newData))
    }
  }

  // 转换函数
const transformData = (input) => {
  const companyNames = {};
  const financialIndicators = [];

  input.forEach(item => {
      // 收集企业名称
      companyNames[item.entityId] = item.企业名称;

      // 收集财务指标
      item.financialIndicator.forEach((indicator, index) => {
          const indicatorIndex = financialIndicators.findIndex(fin => fin.comparative === indicator.indicatorName);
          if (indicatorIndex === -1) {
              financialIndicators.push({
                  [item.entityId]: formatNumberWithCommas(indicator.indicatorValue),
                  key: index + 1,
                  comparative: indicator.indicatorName,
              });
          } else {
              financialIndicators[indicatorIndex][item.entityId] = formatNumberWithCommas(indicator.indicatorValue);
          }
      });
  });
  
  // 添加第一行企业名称
  const firstRow = {
      key: 0,
      comparative: " ",
      ...companyNames
  };

  financialIndicators.unshift(firstRow);
  return financialIndicators;
};
  
const transformData_special = (data) => {
  // 初始化结果数组
  const result = [];

  // 添加银行名称行
  const bankNamesRow = {
      key: 0,
      comparative: " ",
  };

  // 添加银行数据
  data.forEach(bank => {
      bankNamesRow[bank['中文全称']] = bank['中文全称'];
  });
  result.push(bankNamesRow);

  // 处理各个财务指标
  const indicators = data[0].financialIndicator.map(ind => ({
    name: ind.indicatorName,
    values: {}
  }));

  data.forEach(bank => {
    bank.financialIndicator.forEach(ind => {
        const indicatorEntry = indicators.find(i => i.name === ind.indicatorName);
        if (indicatorEntry) {
            indicatorEntry.values[bank['中文全称']] = formatNumberWithCommas(ind.indicatorValue);
        }
    });
  });
  // 构建结果
  indicators.forEach((indicator, index) => {
    const indicatorRow = {
        key: index + 1,
        comparative: indicator.name,
    };
    Object.entries(indicator.values).forEach(([bankName, value]) => {
        indicatorRow[bankName] = value;
    });
    result.push(indicatorRow);
  });
  return result;
}

  return (
    <div className={Style.IndicatorsAndComparisonTableList_table}>
      <Table 
        size="middle"
        // rowClassName={(record, index) => index % 2 === 0 ? Style["even"] : Style["odd"]}
        bordered
        rowKey={(record) => record.key}
        pagination={false}
        columns={columns}
        dataSource={tableData}
      />
    </div>
  )
}

export default IndicatorsAndComparisonTableList
