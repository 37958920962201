import actionTypes from '@/redux/type'

export const initialState = {
  user_login_status: false, //用户登录状态
  user_account_info: {account:'', name:'', email:'', emailStatus:false, userId:'', level:0}, //用户信息
}
export const reducer = () => {
  return [
    // 缓存用户身份信息
    { type: actionTypes.SET_USER_ACCOUNT_INFO, state: (state, action)=> ({
        ...state, 
        user_account_info: action.payload
      }) 
    },
    // 缓存用户登录状态
    { type: actionTypes.SET_USER_LOGIN_STATUS, state: (state, action)=> ({
        ...state, 
        user_login_status: action.payload
      }) 
    }
  ]
}
