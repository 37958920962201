import React, { useState, useEffect } from 'react'
import { Radio, Table, Spin } from "antd";
import { deepCopy } from '../../../../utils'
import bndSheetData from "./data-bnd.json";
import bankSheetData from "./data-bank.json";
import fsSheetData from "./data-fs.json";
import { fieldBold, diffIndent, indentation, tableDataSwitch, doubleIndent } from './automaticCalculation'
import s from "./style.module.scss";

const options = {
  1: [
    { label: "资产负债表", value: "fsCombasList" },
    { label: "利润表", value: "fsCominsList" },
    { label: "现金流量表", value: "fsComscfdList" },
    { label: "财务分析表", value: "fsFinancialAnalysisList" }
  ],
  2: [
    { label: "资产负债表", value: "bankCombasList" },
    { label: "利润表", value: "bankCominsList" },
    { label: "现金流量表", value: "bankComscfdList" },
    { label: "财务分析表", value: "bankFinancialAnalysisList" }
  ],
  3: [
    { label: "资产负债表", value: "bndCobodbsList" },
    { label: "利润表", value: "bndBodproList" },
    { label: "现金流量表", value: "bndBodcaflotList" },
    { label: "财务分析表", value: "bndFinancialAnalysisList" }
  ]
}

const FinancialStatements = ({dataSource}) => {
  const [useDataType, setUseDataType] = useState(0)
  const [newData, setNewData] = useState({})
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [cashFlowSheet, setCashFlowSheet] = useState([]);
  const [incomeSheet, setIncomeSheet] = useState([]);
  const [financialSheet, setFinancialSheet] = useState([]);
  const [value, setValue] = useState('');
  const [curData, setCurData] = useState([])
  const [expand, setExpand] = useState([])
  const [isSpining, setIsSpining] = useState(false)
  const [columns, setColumns] = useState([])
  const [radioOptions, setRadioOptions] = useState([])

  useEffect(() => {
    getUseData()
  }, [])
  useEffect(() => {
    if(Object.keys(newData).length == 0) return
    renderTable(newData);
  }, [newData])

  useEffect(() => {
    if (radioOptions.length > 0) {
      onChange({ target: { value: radioOptions[0].value } })
    }
  }, [radioOptions])

  useEffect(() => {
    if ( balanceSheet.length > 0 && cashFlowSheet.length > 0 && incomeSheet.length > 0 && financialSheet.length > 0){
      juageNowData(value)
    }
  }, [balanceSheet, cashFlowSheet, incomeSheet, financialSheet])

  const getUseData = () => {
    try {
      let type = ''
      const { bankId, bndId, fsId } = dataSource?.businessAssociation
      if (bndId) type = 3
      if (fsId) type = 1
      if (bankId) type = 2
      if (type === '') return setCurData([])
      setRadioOptions(options[type])
      getFinancialInfo(type);
      setUseDataType(type)
    } catch (error) {
      console.log(error, 'error')
    }
  }
  const renderTable = (res) => {
    const balanceSheet = getSheetData(res, options[useDataType][0].value); // 资产负债表
    const incomeSheet = getSheetData(res, options[useDataType][1].value); // 利润表
    const cashFlowSheet = getSheetData(res, options[useDataType][2].value); // 现金流量表
    const financialSheet = getSheetData(res, options[useDataType][3].value); // 财务分析表
    setBalanceSheet(balanceSheet);
    setCashFlowSheet(cashFlowSheet);
    setIncomeSheet(incomeSheet);
    setFinancialSheet(financialSheet);
  };
  const getSheetData = (res, type) => {
    let sheetData = {}
    if (useDataType === 1) sheetData = fsSheetData
    if (useDataType === 2) sheetData = bankSheetData
    if (useDataType === 3) sheetData = bndSheetData
    let formatType = ''
    switch(true) {
      case type === options[useDataType][0].value:
        formatType = '资产负债表'
        break;
      case type === options[useDataType][1].value:
        formatType = '利润表'
        break;
      case type === options[useDataType][2].value:
        formatType = '现金流量表'
        switch(true) {
          case useDataType === 1:
            let tempArr1 = res['fsComscfiList']
            let newRes1 = res[type].reduce((acc, cur, index) => {
              if (cur.accper === tempArr1[index].accper) {
                let obj = {...tempArr1[index], ...cur}
              acc.push(obj)
              return acc
              }
            }, [])
            res[type] = newRes1
            break;
            case useDataType === 2:
              let tempArr2 = res['bankComscfiList']
              let newRes2 = res[type].reduce((acc, cur, index) => {
                if (cur?.accper === tempArr2[index]?.accper) {
                  let obj = {...tempArr2[index], ...cur}
                acc.push(obj)
                return acc
                }
              }, [])
              res[type] = newRes2
              break;
              case useDataType === 3:
                let tempArr3 = res['bndBodcaflotimList']
                let newRes3 = res[type].reduce((acc, cur, index) => {
                  if (cur.accper === tempArr3[index].accper) {
                    let obj = {...tempArr3[index], ...cur}
                  acc.push(obj)
                  return acc
                  }
                }, [])
                res[type] = newRes3
                break;
        }
        break;
      case type === options[useDataType][3].value:
      formatType = '财务分析表'
      break;
    }
    let dataSheet = {};
    const newDataSource = {};
    try {
      Object.keys(res[type][0])?.forEach((i) => {
        dataSheet[i] = {};
      });
      const {id, accper, ifcorrect, shortname, stkcd, typrep, ...data} = dataSheet
      dataSheet = data
      for (let outItem in dataSheet) {
        for (let intItem in res[type]) {
          dataSheet[outItem][res[type][intItem].id] = res[type][intItem][outItem]
        }
      }
      for (let outItem in res[type]) {
        newDataSource[res[type][outItem].id] = res[type][outItem]?.accper || res[type][outItem]?.enddate
      }
    } catch (error) {
      console.error(error, 'error')
      // throw new Error('数据格式错误')
    }
    const dataSource = Object.keys(sheetData[formatType]).map((header,idx) => {
      const children = [];
      const balanceSheetContrast = Object.keys(sheetData[formatType][header]);
      balanceSheetContrast.forEach((item) => {
        if (Object.keys(dataSheet).find((name) => name === item)) {
			let flag = Object.values(dataSheet[item]).every(function(value) { return value === 0; });
          if(!flag) children.push({
            key: item + '-' + idx,
            // key:item,
            type: formatType === '财务分析表' ? 1 : 0,
            ...dataSheet[item],
            sort: sheetData[formatType][header][
                Object.keys(dataSheet).find((name) => name === item)
            ],
          })
        }
      })
      return {
        key: header,
        sort: header,
        type: formatType === '财务分析表' ? 1 : 0,
        ...newDataSource,
        children: children,
      };
    })
    let titleObj = sheetData['表格标题']
    if (formatType === '财务分析表') {
      setHeaderData(dataSource, titleObj, 3, formatType, res)
    }
    if (formatType === '现金流量表') {
      setHeaderData(dataSource, titleObj, 2, formatType, res)
    }
    if (formatType === '利润表') {
      setHeaderData(dataSource, titleObj, 1, formatType, res)
    }
    if (formatType === '资产负债表') {
      setHeaderData(dataSource, titleObj, 0, formatType, res)
    }
    return dataSource;
  }
  const setHeaderData = (data, titleObj, type, formatType, res) => {
    data.forEach((i, index) => {
      if (Object.keys(titleObj).includes(i.sort)) {
        i.key = titleObj[i.sort]
        Object.keys(i).map((el, idx) => {
          if(!Number.isNaN(Number(el))) {
            res[options[useDataType][type].value].map((ele) => {
              if(ele.id == el) {
                i[el] = ele?.[titleObj[i.sort]]
              }
            })
            // i[el] = res[options[useDataType][type].value][idx]?.[titleObj[i.sort]]
          }
        })
      }
    })
    tableDataSwitch(data, formatType)
  }
  const getFinancialInfo = async (type) => {
    const newData = deepCopy(dataSource)
    initDataDeleteNull(newData, type)
  };

  //数据初始化(将为空的数据删除)
  const initDataDeleteNull = (tableInfo, type) => {
    delete tableInfo.businessAssociation;
    for (let _key in tableInfo) {
      if (type === 1 && _key.indexOf('fs') < 0) {
        delete tableInfo[_key];
      } else if (type === 2 && _key.indexOf('bank') < 0) {
        delete tableInfo[_key];
      } else if (type === 3 && _key.indexOf('bnd') < 0) {
        delete tableInfo[_key];
      } else if (Array.isArray(tableInfo[_key]) && tableInfo[_key].length >= 2) {
        let arr = tableInfo?.[_key]?.reduce((acc, cur, index) => {
          acc.push(index)
          return acc
        }, [])
        arr?.forEach(index => {
          for (let _i in tableInfo[_key][index]) {
            if (tableInfo[_key][index][_i] === null) {
              delete tableInfo[_key][index][_i];
            }
          }
        });
      }
    }
    setNewData(tableInfo);
  };

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);
    getColumns(value);
    juageNowData(value)
  };
  //数字转化为千分位
  const geThousandsNUmber = (str) => {
    return (str + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
  }
  const getColumns = (curType) => {
    if (curType === '') return []
    const columns = [
      {
        title: `年度报表`,
        colSpan: 1,
        children: [{ 
          title: '',
          dataIndex: 'sort',
          // fixed: 'left',
          // width: 400,
          key: 'sort',
          render: (text, record) => {
            return <span
            style={{
              paddingLeft: indentation.includes(record.key) ? '30px' : doubleIndent.includes(record.key) ? '60px' : '0',
              fontWeight: fieldBold.includes(record.key) ? 'bold' : 'normal',
              marginLeft: diffIndent.includes(record.key) ? '-30px' : '0'
            }}
          >{text}</span>
          }
          
        },]
      },
      {
        title: <div style={{ display: 'flex' }}>
          {
            dataSource[curType]?.map((item, index, arr) => {
              return <span
              style={{
                flex: 1,
                display: 'inline-block',
                textAlign: 'center',
                paddingRight:  index === arr.length - 1 ? '0' : '16px',
                paddingLeft: index === 0 && index !== arr.length - 1  ? '0': '16px' ,
              }}
              key={item.id}
          >{item?.accper?.slice(0, 4) || item?.enddate?.slice(0, 4)}</span>
            })
          }
        </div>,
        colSpan: dataSource[curType]?.length + 1 || 2,
        children: [
        {
          children: getColunmsChildren(dataSource[curType],curType)
        }
      ]
    }
  ]
  setColumns(columns);
};
const getColunmsChildren = (childrenData,curType) => {
  const data = childrenData?.reduce((acc, cur) => {
    let obj = {}
    if(curType == 'bankFinancialAnalysisList') {
      obj = {
        title: cur?.reptdt?.slice(0, 4),
        dataIndex: cur.id,
        key: cur.id,
        // width: 180,
        colSpan: 0,
        align: 'center',
        render: (_, render) => {
          return (_ === '' || _ === undefined)  ? " " : (Number(_).toString() === 'NaN' ? ' ' : <span style={{ color: Number(_) < 0 ? 'red' : '#333' }}>{geThousandsNUmber(Number(_).toFixed(2))}</span>)
        }
      }
    }else {
      obj = {
        title: cur?.accper?.slice(0, 4) || cur?.enddate?.slice(0, 4) || '',
        dataIndex: cur.id,
        key: cur.id,
        // width: 180,
        colSpan: 0,
        align: 'center',
        render: (_, render) => {
          return (_ === '' || _ === undefined)  ? " " : (Number(_).toString() === 'NaN' ? ' ' : <span style={{ color: Number(_) < 0 ? 'red' : '#333' }}>{render.type ? _ : geThousandsNUmber(Number(_).toFixed(2))}</span>)
        }
    }
    }
    acc.push(obj)
    return acc
  }, [])
  return data || []
}
//表格初始化数据
const juageNowData = (value) => {
  const getExpand = (array) => {
      return array.map(item => item.key)
  }
  if (value === options[useDataType][0]?.value) {
	  console.log(balanceSheet,'balanceSheet')
    setCurData(balanceSheet)
    setExpand(getExpand(balanceSheet))
  }
  if (value === options[useDataType][1]?.value) {
	  console.log(incomeSheet,'incomeSheet')
      setCurData(incomeSheet)
      setExpand(getExpand(incomeSheet))
  }
  if (value === options[useDataType][2]?.value) {
	  console.log(cashFlowSheet,'cashFlowSheet')
      setCurData(cashFlowSheet)
      setExpand(getExpand(cashFlowSheet))
  }
  if (value === options[useDataType][3]?.value) {
	  console.log(financialSheet,'financialSheet')
    setCurData(financialSheet)
    setExpand(getExpand(financialSheet))
}
  
  setIsSpining(false)
}
  return (
    <div className={s.page}>
        <Spin spinning={isSpining}>
            <div className={s.bottomSide}>
                <div className={s.headerWrapper}>
                    <div>
                      {
                        radioOptions.length > 0 ? 
                         <>
                          <Radio.Group
                            options={radioOptions}
                            optionType="button"
                            buttonStyle="solid"
                            value={value}
                            onChange={onChange}
                        ></Radio.Group>
                        </>
                        :
                        null
                      }
                        
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={curData}
                    scroll={{ x: '100%' }}
                    sticky={{ offsetHeader: 64 }}
                    pagination={false}
                    expandable={{
                      expandedRowKeys: expand,
                      indentSize: 0,
                      onExpand: (ex, record) => {
                        let arr = [...expand]
                        ex ? arr.push(record.key) : arr.splice(arr.findIndex(item => item === record.key), 1)
                        setExpand(arr)
                      }
                    }}
                    rowClassName={(record, idx) => {
                        if (idx % 2 === 1 && !record.children)
                            return s.bgRow;
                    }}
                    rowKey={(record,idx) => {
                      return record.key
                    }}
                />
            </div>
        </Spin>
    </div>
)
}

export default FinancialStatements;