import React, {} from 'react'
import {Skeleton} from 'antd'
import style from './index.module.scss'

const ContainerBox = (props) => {
  const {title='',loading=true, id="",isQualityArea= false} = props
  return <div className={style.containerBox_container}>
    <div className={style.containerBox_wrapper} id={id}>
      <Skeleton loading={loading}>
        { title
            ?<div className={style.containerBox_title} style={isQualityArea ? {marginBottom:'1px'} :{}}>
              <div>{title}</div>
            </div>
            :null
        }
        {props.children}
      </Skeleton>
    </div>
  </div>
}

export default ContainerBox